import React from "react";
import './Application.scss';
import { useLocation } from "react-router-dom";
import FormBanner from "./FormBanner";
import DigitalAgreement from "./DigitalAgreement";
import Payment from "./Payment";
import { useState } from "react";



const ApplicationFormThree = () => {
    const [displayScreen, setDisplayScreen] = useState('agreement');
    const location = useLocation();

    const updateUi = (e) => {
        setDisplayScreen(e)
    };

    return (
        <div className="grid">
            <FormBanner />
            <div className="form-layout">
                {displayScreen === 'agreement' ? <DigitalAgreement updateUi={(e) => updateUi(e)} /> :
                    <Payment updateUi={(e) => updateUi(e)} />}
            </div >
        </div>
    )
}

export default ApplicationFormThree;