import * as Yup from 'yup';
const panRegex = /([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}$/;
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const expRegex = /^\d+$/;
const mobileRegExp = /^\d{10}$/;
const intrestRateRegEx = /^(?:0|[1-9]\d?|30)(?:\.\d+)?$/;


export const StudentFormSchema = Yup.object({
    instituteId: Yup
        .string().required('Institute Name is required'),
    state: Yup
        .string().required('State is required'),
    courseId: Yup
        .string().required('Course is required'),
    acdemicYear: Yup
        .string().required('Academic Year is required'),
    firstName: Yup
        .string().required('First Name is required'),
    lastName: Yup
        .string().required('Last Name is required'),
    instituteStudentId: Yup
        .string().required('Student ID is required'),
    studenttype: Yup
        .string().required('Student Type is required'),
    class: Yup
        .string().required('Class is required'),
    courseFee: Yup.string().required('Amount Required is required').matches(
        expRegex,
        "Amount Required is not valid"
    )
});

export const KycSchema = Yup.object({
    pan: Yup.string().required('PAN is required')
        .matches(
            panRegex,
            "PAN is not valid"
        ),
    fullName: Yup.string().required('Full Name is required'),
    phone: Yup.string().required('Phone Number is required').matches(
        mobileRegExp,
        "Phone Number is not valid"
    ),
    email: Yup.string().required('Email is required').matches(
        emailRegex,
        "Email is not valid"
    ),
    dob: Yup.string().required('Date of Birth is required'),
    maritalStatus: Yup.string().required('Marital Status is required'),
    bankCode: Yup.string().required('Bank is required'),
    accountNo: Yup.string().required('Account Number is required'),
    gender: Yup.string().required('Gender is required'),
    employmentType: Yup.string().required('Employee Type is required'),
    yoe: Yup.string().required('Years of Experience is required').matches(
        expRegex,
        "Years of Experience is not valid"
    ),
    address: Yup.string().required('Address is required'),
});

export const InstititeFormSchema = Yup.object().shape({
    instituteName: Yup.string().required('Institute name is required'),
    acdemicYear: Yup.array().required('Academic year is required'),
    status: Yup.string().required('Status is required'),
    interestRate: Yup.string().required('Intrest rate is required').matches(
        intrestRateRegEx,
        "Intrest Rate is not valid"
    ),
    location: Yup.string().required('location is required'),
    state: Yup.string().required('State is required'),

});

export const BankDetailsSchema = Yup.object().shape({
    beneficiaryName: Yup.string().required('Beneficiary Name is required'),
    accountNumber: Yup.array().required('Account Number is required'),
    ifsc: Yup.string().required('IFSC is required')
});

export const CourseFormSchema = Yup.object().shape({
    instituteName: Yup.string().required('Institute name is required'),
    acdemicYear: Yup.string().required('Academic year is required'),
    courseName: Yup.string().required('Course name is required'),
    class: Yup.string().required('Class is required'),
    coursefee: Yup.string().required('Course fee is required').matches(
        expRegex,
        "Course fee is not valid"
    ),
    state: Yup.string()
});
export const BoardFormSchema = Yup.object().shape({
    board: Yup.string().required('Board is required'),
});