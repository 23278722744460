import React, { useRef, useState } from "react";
import StatusGrid from "./StatusGrid";
import NewUserBanner from "./NewUserBanner";
import './Home.scss';
import { useEffect } from "react";
import { Toast } from "primereact/toast";
import api from "../../api/services";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const NewUserHome = () => {
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    });
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const toast = useRef(null);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [loanStatus, setLoanStatus] = useState('DRAFT');
    const [applicationNo, setApplicationNo] = useState('');

    useEffect(() => {
        if (currentUser?.studentId)
            trackApplicationStatus();
    }, []);

    const trackApplicationStatus = () => {
        return
        api.get('/applicationStatus/' + currentUser?.studentId).then(res => {
            if (res?.status === 200) {
                setLoanStatus(!res.data.loanStatus.status ? 'DRAFT' : res.data.loanStatus.status);
                setApplicationNo(res.data.applicationNumber);
                localStorage.setItem('applicationInfo', JSON.stringify(res.data));
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: '', detail: err.message });
        })
    };
    return (
        <>
            <NewUserBanner />
            <StatusGrid loanStatus={loanStatus} applicationNo={applicationNo}></StatusGrid>
            <Toast ref={toast} />
        </>
    )
}


export default NewUserHome;