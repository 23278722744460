import React, { useState } from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import { BreadCrumb } from 'primereact/breadcrumb';
import InstituteForm from "./InstituteForm";
import BranchesList from "./BranchesList";
import BankDetails from "./BankDetails";
import { useNavigate } from "react-router-dom";

const AddNewInstitute = () => {
    const navigate = useNavigate()
    const [activeIndex, setActiveIndex] = useState(0);

    const items = [{ label: 'New Institute' }];
    const home = { icon: 'pi pi-home', onclick: () => navigate('/institutes') }

    return (
        <>
            <BreadCrumb model={items} home={home} />
            <div className="card">
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Institute Details">
                        <InstituteForm />
                        <BranchesList goToBranchTab={() => setActiveIndex(1)} />
                    </TabPanel>
                    <TabPanel header="Branches">
                        <BankDetails />
                    </TabPanel>
                </TabView>

            </div>
        </>
    )
}

export default AddNewInstitute;