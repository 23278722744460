import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import OTPInput from "otp-input-react";
import './OtpPopup.scss';
import api from "../../api/services";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const OtpPopup = (props) => {
    const toast = useRef(null);
    const navigate = useNavigate();
    const [time, setTime] = useState(null);
    const [loading, setLoading] = useState(false);
    const [otpEntered, setOtpEntered] = useState(false);
    const [OTP, setOTP] = useState(null);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const footerContent = (
        <Button label="Submit" className="w-full" onClick={() => onClickSubmit()} disabled={loading} />
    );

    useEffect(() => {
        setTime(60);
    }, [])

    const onClickSubmit = () => {
        setLoading(true);
        if (OTP === null || OTP.length !== 6) {
            toast.current.show({ severity: 'error', summary: 'ERROR', detail: 'Please enter valid OTP' });
            setLoading(false);
            return;
        }
        api.post('/scoreme/EquifaxValidateOTP/' + currentUser.studentId, { referenceId: props.referenceId, otp: OTP }).then(res => {
            if (res.status === 200 && res.data?.result.responseCode === 'SRS016') {
                toast.current.show({ severity: 'success', summary: 'SUCCESS', detail: 'OTP Submitted successfully' });
                props.saveParent();
            } else {
                toast.current.show({ severity: 'error', summary: 'ERROR', detail: res.data?.result.responseMessage });
            }
            setLoading(false);
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            setLoading(false);
            toast.current.show({ severity: 'error', summary: 'ERROR', detail: err.message });
        });
    }

    const getTime = () => {
        if (props.visible) {
            if (time < 0) {
                return;
            }
            setTimeout(() => {
                setTime(time - 1);
            }, 1000);
            return "00:" + time;
        }
    }

    const resendOtp = () => {
        setTime(60);
        setLoading(true);
        setOtpEntered(false);
        setOTP(null);
        api.post('/scoreme/EquifaxResendOTp', { referenceId: props.referenceId, otp: OTP }).then(res => {
            if (res) {
                toast.current.show({ severity: 'success', summary: 'SUCCESS', detail: 'OTP sent successfully' });
            } else {
                toast.current.show({ severity: 'error', summary: 'ERROR', detail: res?.responseMessage });
            }
            setLoading(false);
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            setLoading(false);
            toast.current.show({ severity: 'error', summary: 'ERROR', detail: err.message });
        });
    };

    return (
        <Dialog header="Enter Your OTP" visible={props.visible} footer={footerContent} onHide={() => { props.hidePop(false); setOTP(''); setOtpEntered(false) }} >
            <p className="font-l text-sm mt-0">Verify your complete KYC with OTP</p>
            <div className="flex flex-column mt-3 align-items-center mb-4 otp-form">
                <OTPInput disabled={loading} value={OTP} onChange={(e) => { setOTP(e); setOtpEntered(true) }} OTPLength={6} otpType="number" secure placeholder={['-', '-', '-', '-', '-', '-']} />
            </div>
            {time > 0 && !otpEntered && <p className="font-l text-sm  mt-3">Wait <span>{getTime()}</span> to resend OTP</p>}
            {time === 0 && !otpEntered && <div className="flex justify-content-between flex-wrap mt-3">
                <div className="flex align-items-center justify-content-center ">00:00</div>
                <div className="flex align-items-center justify-content-center ">
                    <a onClick={() => resendOtp()}>Resend OTP</a>
                </div>
            </div>}
            {otpEntered && <div className="flex justify-content-end flex-wrap mt-4">
                <a onClick={() => { resendOtp() }}>Resend OTP</a>
            </div>}
            <Toast ref={toast} />
        </Dialog>
    )
}

export default OtpPopup;