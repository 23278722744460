import React, { useEffect } from 'react';
import './App.css';
import Routing from './routing/Routing';
import { useSelector } from 'react-redux';
import Header from './components/header/Header';
import SideMenu from './components/sideMenu/SideMenu';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import Footer from './components/footer/Footer';
import { useMediaQuery } from 'react-responsive';
import MobileHeader from './components/mobileHeader/MobileHeader';

function App() {
  const auth = useSelector((state) => state.auth.value)
  const location = useLocation();
  const navigate = useNavigate();
  let currentUser = localStorage.getItem('currentUser');
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 768px)'
  });
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    if (!currentUser) {
      navigate('/otp-login');
    }
  }, [localStorage.getItem('currentUser')]);

  useEffect(() => {
    if (auth) {
      localStorage.clear();
      navigate('/otp-login');
    }
  }, [auth])

  const handleOnIdle = () => {
    localStorage.clear();
    navigate('/otp-login');
  }
  const handleOnActive = (event) => {
    // console.log('handleOnActive', event);
  }

  const handleOnAction = (event) => {
    // console.log('handleOnAction', event);
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 60 * 24,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })

  return (
    <>
      {currentUser ? <div className='app-fluid'>
        {isDesktopOrLaptop && <Header />}
        {isMobile && <MobileHeader />}

        {(location.pathname !== '/' && location.pathname !== '/new-user-home' && location.pathname !== '/otp-existing-user') && <div className='side-menu'>
          <SideMenu />
        </div>}
        <div className='container'>
          <div className={(location.pathname !== '/' && location.pathname !== '/new-user-home' && location.pathname !== '/otp-existing-user') ? "routing-container" : 'routing-container-full'}>
            <Routing />
          </div>
        </div>
      </div> : <div className='app'>
        {isDesktopOrLaptop && <Header />}
        {isMobile && <MobileHeader />}
        <div className='container'>
          <div className='routing-container-full'>
            <Routing />
          </div>
        </div>
      </div>}
    </>
  );
}

export default App;