import React from "react";
import LAUNCH from '../../assets/images/launch.svg';
import { Button } from 'primereact/button';
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const NoRewards = () => {
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    });
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    return (
        <>
            <div className='flex justify-content-center align-items-center '>
                <img src={LAUNCH} alt="no user" className={isMobile ? 'w-6' : 'w-9'} />
            </div >
            <div className='flex justify-content-center align-items-center '>
                <p className="title mb-0">We are on the way</p>
            </div >
            <div className='flex justify-content-center '>
                <p className="font-l text-base light-text w-8 text-center">We are working on geting this feature to you soon</p>
            </div >
            <div className='flex justify-content-center align-items-center '>
                <Button label="Go to Home" className="p-button-outlined mr-2" onClick={navigate('/new-user-home')} />
            </div>
        </>
    )
}

export default NoRewards;