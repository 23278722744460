import React from "react";
import { useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import AddCourse from "./AddCourse";
import { Toast } from 'primereact/toast';
import api from "../../api/services";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { Dropdown } from "primereact/dropdown";

const Courses = () => {
    const navigate = useNavigate();
    const toast = useRef(null);
    const [courses, setCourseList] = useState([]);
    const [institutes, setInstitutes] = useState([]);
    const [record, setRecord] = useState(null);

    const [type, setType] = useState('');
    const [filter, setFilter] = useState({
        instituteName: '',
        location: '',
        acdemicYear: '',
        board: ''
    })
    const [action, setAction] = useState('Add');
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getMeInstituteList();
    }, []);

    useEffect(() => {
        if (filter.instituteName !== '' && filter.location !== '')
            getMeCoursesList();
    }, [filter]);

    const getMeInstituteList = () => {
        api.get('/institute').then(response => {
            if (response.status === 200) {
                setInstitutes(response.data);
            }
            setLoading(false);
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            setLoading(false);
        })
    };

    const getMeBoardID = (obj) => {
        let boardId = '';
        institutes.forEach(element => {
            if (element.instituteName === obj.instituteName && element?.boards?.length) {
                element.boards?.forEach(item => {
                    if (item.boardName === obj.board) {
                        boardId = item._id
                    }
                });
            };
        })
        return boardId;
    }
    const getInstID = (obj) => {
        return institutes.filter(element => {
            return element.instituteName === obj.instituteName;
        })[0]._id;
    }

    const getMeCoursesList = () => {
        let obj = { ...filter };

        if (obj.board && filter.obj !== '') {
            obj['boardId'] = getMeBoardID(obj)
        }
        if (obj.instituteName && filter.instituteName !== '') {
            obj['instituteId'] = getInstID(obj)
        }
        delete obj.board
        api.get('/course/getCourses', {
            params: obj
        }
        ).then(response => {
            if (response?.status === 200) {
                setCourseList(response.data.data)
            } else {

            }
            setLoading(false);
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', detail: err.message });
            setLoading(false);
        })
    }

    const onChangeFilter = (key, value) => {
        setFilter({
            ...filter,
            [key]: value
        })
    }
    const hidePopup = () => {
        getMeCoursesList();
        setVisible(false);
    }
    const setActions = (value) => {
        return <>
            <i className="pi pi-pencil pointer mr-2" onClick={() => onClickEdit(value)}></i>
            <i className="pi pi-trash pointer" onClick={() => deleteInstitute(value)}></i>
        </>
    }
    const onClickEdit = (value) => {
        setVisible(true);
        setRecord(value);
        setAction('Edit');
    }

    const deleteInstitute = (value) => {
        setLoading(true);
        api.delete('/course/deleteCourse/' + value._id).then(res => {
            if (res.status === 200 && res.data?.result !== null) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Course deleted successfully' });
                getMeCoursesList();
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res?.data.message });
            }
            setLoading(false);
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
            setLoading(false);
        });
    };

    const getMeStatesList = () => {
        if (filter.instituteName !== '')
            return institutes.filter(obj => {
                return obj.instituteName === filter.instituteName
            }).filter((v, i, a) => a.findIndex(v2 => (v2.location === v.location)) === i)
    };

    const getMeCoursedrodownList = () => {
        let list = [];
        if (filter.instituteName !== '') {
            institutes.forEach(obj => {
                if (obj.instituteName === filter.instituteName)
                    if (obj?.boards)
                        list = obj.boards;
            })
        }
        return list
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="grid">
                    <div className="col-3">
                        <Dropdown onChange={(e) => {
                            onChangeFilter('instituteName', e.target.value);
                        }} options={institutes}
                            value={filter.instituteName}
                            optionLabel="instituteName" optionValue="instituteName"
                            placeholder="Select Institute" className="w-full" />
                    </div>
                    <div className="col-3">
                        <Dropdown name="location" onChange={(e) => {
                            onChangeFilter('location', e.target.value);
                        }} value={filter.location} options={getMeStatesList()} optionLabel="location" optionValue="location" placeholder="Select Location" className="w-full" />
                    </div>
                    <div className="col-3">
                        <Dropdown options={[]}
                            optionLabel="label" optionValue="value"
                            placeholder="Select Branch" className="w-full" />
                    </div>
                    <div className="col-3">
                        <Dropdown name="Board" onChange={(e) => {
                            onChangeFilter('board', e.target.value);
                        }} value={filter.board} options={getMeCoursedrodownList()} showClear optionLabel="boardName" optionValue="boardName" placeholder="Select Board" className="w-full" />

                    </div>
                </div>

            </div>
            <div className="col-12 pt-0 pb-0">
                <p className="m-0">*Please select Institute and Location to get course list</p>
            </div>
            <div className="col-12">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex align-items-center justify-content-center">
                        <p className="page-title mb-0">Courses</p>
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <Button label="Add" onClick={() => { setAction('Add'); setVisible(true); setType('course'); }} disabled={filter.instituteName === '' || filter.location === ''} />
                    </div>
                </div>
            </div>

            <div className="col-12">
                <DataTable value={courses} paginator rows={10} loading={loading}>
                    <Column field="courseName" header="Board"></Column>
                    <Column field="class" header="Class"></Column>
                    <Column field="coursefee" header="Course Fee"></Column>
                    <Column field="" header="Actions" body={(e) => setActions(e)}>
                    </Column>
                </DataTable>
            </div>
            {visible && <AddCourse visible={visible} filter={filter} hidePopup={hidePopup} setVisible={(e) => { setRecord(null); setVisible(e) }} getMeCoursesList={(e) => getMeCoursesList()} record={record} action={action} type={type} courses={getMeCoursedrodownList()} />}
            <Toast ref={toast} />
        </div>
    )
}


export default Courses;