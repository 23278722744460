import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import React, { useState } from "react"

const Users = () => {
    const [users, setUsers] = useState([]);
    const [toast, setToast] = useState(null);
    const columns = [
        { field: 'empNo', header: 'Employee Number', width: '200px', filter: true },
        { field: 'accessDate', header: 'Access Date', width: '200px', filter: true },
        { field: 'name', header: 'Name', width: '200px', filter: true },
        { field: 'emial', header: 'Email ID', width: '200px', body: true, filter: true },
        { field: 'designation', header: 'Employee Designation', width: '200px', filter: true },
        { field: 'reportingManager', header: 'Reporting Manager', width: '200px', filter: true },
        { field: 'role', header: 'Role', width: '200px', filter: true },
        { header: 'Actions', field: 'actions', width: '200px', body: true, width: '300px' },
    ];
    const setBodyTemplate = (e, col) => {
        switch (col.field) {
            default:
                return (
                    <>
                        <div>
                            {e.applicationStatus?.KYC_FORM === 'SUCCESS' ? <div>
                                {e.loanStatus.status === 'DRAFT' || e.loanStatus.status === 'PENDING' ? <>
                                    <Button label="Approve" size="small" className="success" onClick={() => { onClickAction(e, 'APPROVE') }} />
                                    <Button label="Reject" size="small" className="ml-2 danger" onClick={() => { onClickAction(e, 'REJECT') }} />
                                </> : <p className={`m-0 ${e.loanStatus.status}`}>
                                    {e.loanStatus.status}
                                </p>}
                            </div> : "KYC Pending"
                            }
                        </div>
                        <Button label="Delete" size="small" className="success ml-2" onClick={() => { onClickAction(e, 'DELETE') }} />
                    </>
                )
                break;
        }
    };

    const onClickAction = (element, type) => {


    }

    return (
        <div className="grid">
            <div className="col-12">
                <p className="page-title mb-0">Users</p>
            </div>
            <div className="col-12">
                <DataTable value={users} scrollable paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} scrollHeight="700px" filterDisplay="row" >
                    {columns.map((col, i) => (
                        <Column filter={col.filter} key={col.field} field={col.field} header={col.header} style={{ minWidth: col.width }} body={col.body ? (e) => setBodyTemplate(e, col) : null}></Column>
                    ))}
                </DataTable>
            </div>
            <Toast ref={toast} />
        </div>
    )
}
export default Users;