import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import BANNER_LOGO from '../../assets/images/banner-logo.svg';
import BANNER_LOGO_MOBILE from '../../assets/images/banner-logo-mobile.svg';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import RejectPopup from "../../components/rejectPopup/RejectPopup";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";


const NewUserBanner = () => {
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    });
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const applicationInfo = JSON.parse(localStorage.getItem('applicationInfo'));
    const [visiblePopup, setVisiblePopup] = useState(false);

    const onClickFillApplication = () => {
        if (applicationInfo?.loanStatus?.status === "DECLINED") {
            setVisiblePopup(true)
        } else {
            console.log('else');
            // return
            navigate('/application-form-one');
        }
    };

    return (
        <div className={"flex-column banner-container p-4 relative" + " " + (isMobile ? 'banner-bg' : '')}>
            <p className="welcome">
                ACCESSIBLE EDUCATION TO ALL
            </p>
            <div className="flex  m-2">
                <h1 className="mt-1 mb-1">Fastest & Easiest</h1>
            </div>
            <div className="flex  m-2">
                <h1 className="mt-1 mb-1">Educational</h1>
            </div>
            <div className="flex  m-2">
                <h1 className="mt-1 mb-1">Payment Mode</h1>
            </div>
            <div className="flex  m-2 pt-5">
                <Button label="Fill an Application" aria-label="Submit" onClick={() => onClickFillApplication()} />
            </div>
            {isDesktopOrLaptop && <img src={BANNER_LOGO} alt="banner logo" className="absolute top-0 right-0 h-full" />}
            <ConfirmDialog />
            <RejectPopup visible={visiblePopup} setVisible={() => { setVisiblePopup(false) }} />
        </div>
    )
}

export default NewUserBanner;