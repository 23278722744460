import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import './Login.scss';
import OTP_IMG from '../../assets/images/OTP.svg';
import OTPInput from "otp-input-react";
import { useLocation, useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import api from "../../api/services";
import { useMediaQuery } from 'react-responsive';


const OtpForm = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    });
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const toast = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    let values = { phone: location.state };
    const [OTP, setOTP] = useState("");
    const [otpEntered, setOtpEntered] = useState(false);
    const [loading, setLoading] = useState(false);
    const [time, setTime] = useState(60);
    const getTime = () => {
        if (time < 0) {
            return;
        }
        setTimeout(() => {
            setTime(time - 1)
        }, 1000);
        return "00:" + time;
    }

    const handleSubmit = () => {
        setLoading(true);
        validateOtp();
    }

    const validateOtp = () => {
        api.post('/verify-otp', { phoneNumber: values.phone, otp: OTP }).then(res => {
            setLoading(false);
            let loginUser;
            if (res.status === 200) {
                // dispatch(authorized())
                if (res.data?.result?.length === 0 || res.data?.result[0].userType === 'New') {
                    loginUser = res.data?.result?.length === 0 ? { phone: values.phone, userType: 'New' } : res.data?.result[0];
                    loginUser.studentId = res.data?.result?.length > 0 ? res.data?.result[0]._id : '';
                    navigate('/new-user-home');
                } else {
                    navigate('/dashboard');
                }
                localStorage.setItem('currentUser', JSON.stringify(loginUser));
                localStorage.setItem('token', res.data?.data?.token);

            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Invalid OTP' });
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            setLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
        });
    }

    const resendOtp = () => {
        setLoading(true);
        setOTP('');
        api.get('/resendOtp/' + values.phone).then(res => {
            setLoading(false);
            if (res.status === 200) {
                setTime(60); setOtpEntered(false); setOTP('');
                toast.current.show({ severity: 'success', summary: '', detail: 'OTP hasbeen sent succesfuly' });
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Invalid OTP' });
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            setLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
        });
    }


    return (
        <>
            {isDesktopOrLaptop && <div className="grid">
                <div className="col-12 md:col-6 lg:col-6">
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-12">
                            <div className="otp-form">
                                <p className="login-title text-4xl font-semi mt-8 pt-8 mb-1">Enter your OTP</p>
                                <p className="sub-title font-l mt-0 mb-4">A 4 digit OTP has been send to your mobile (One time password) on your mobile number {values.phone.replace(/.{0,8}$/, '') + " xxxxxxxx"}</p>
                                <div className="flex flex-column mt-3 align-items-center mb-4">
                                    {loading && <ProgressSpinner />}
                                    <OTPInput value={OTP} onChange={(e) => { setOTP(e); setOtpEntered(true) }} OTPLength={4} otpType="number" disabled={false} secure placeholder={['-', '-', '-', '-']} />
                                </div>
                                <div className="flex flex-column mt-3">
                                    <Button label="Submit" className="w-full login-button" onClick={handleSubmit} disabled={loading} />
                                </div>
                                {time > 0 && !otpEntered && <p className="font-l text-sm mt-4">Wait <span>{getTime()}</span> to resend OTP</p>}
                                {time === 0 && !otpEntered && <div className="flex justify-content-between flex-wrap mt-4">
                                    <div className="flex align-items-center justify-content-center ">00:00</div>
                                    <div className="flex align-items-center justify-content-center ">
                                        <a onClick={() => { resendOtp(); setTime(60) }}>Resend OTP</a>
                                    </div>
                                </div>}
                                {otpEntered && <div className="flex justify-content-end flex-wrap mt-4">
                                    <a onClick={() => resendOtp()}>Resend OTP</a>
                                </div>}
                                <div className="flex flex-wrap mt-4">
                                    <div className="flex align-items-center justify-content-center ">
                                        <a onClick={() => navigate('/otp-login')}>Go Back</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-6 flex justify-content-center flex-wrap hide-logo">
                    <img src={OTP_IMG} alt="login" className="w-9 " />
                </div>
                <Toast ref={toast} />
            </div>}
            {isMobile && <div className="grid">
                <div className="col-12 md:col-12 lg:col-12 text-center">
                    <img src={OTP_IMG} alt="login" className="w-7" />
                </div >
                <div className="col-12 md:col-12 lg:col-12">
                    <div className="otp-form">
                        <p className="login-title text-2xl text-center mt-0  mb-1">Enter your OTP</p>
                        <p className="sub-title font-l mt-0 mb-2 text-sm">A 4 digit OTP has been send to your mobile (One time password) on your mobile number {values.phone.replace(/.{0,8}$/, '') + " xxxxxxxx"}</p>
                        <div className="flex flex-column mt-3 align-items-center mb-4">
                            {loading && <ProgressSpinner />}
                            <OTPInput value={OTP} onChange={(e) => { setOTP(e); setOtpEntered(true) }} OTPLength={4} otpType="number" disabled={false} secure placeholder={['-', '-', '-', '-']} />
                        </div>
                        <div className="flex flex-column mt-3">
                            <Button label="Submit" className="w-full login-button" onClick={handleSubmit} disabled={loading} />
                        </div>
                        {time > 0 && !otpEntered && <p className="font-l text-sm mt-4">Wait <span>{getTime()}</span> to resend OTP</p>}
                        {time === 0 && !otpEntered && <div className="flex justify-content-between flex-wrap mt-4">
                            <div className="flex align-items-center justify-content-center ">00:00</div>
                            <div className="flex align-items-center justify-content-center ">
                                <a onClick={() => { resendOtp(); setTime(60) }}>Resend OTP</a>
                            </div>
                        </div>}
                        {otpEntered && <div className="flex justify-content-end flex-wrap mt-4">
                            <a onClick={() => resendOtp()}>Resend OTP</a>
                        </div>}
                        <div className="flex flex-wrap mt-4">
                            <div className="flex align-items-center justify-content-center ">
                                <a onClick={() => navigate('/otp-login')}>Go Back</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            }
        </>

    )
}

export default OtpForm;