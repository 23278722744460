import HOME from '../assets/images/home.png';
import REWARDS from '../assets/images/rewards.png';
import PAYMENTS from '../assets/images/payments.png';
import SETTINGS from '../assets/images/settings.png';
import EMI from '../assets/images/emi.png';

export const MENU_ITEMS = [
    {
        label: 'Home',
        icon: HOME,
        priv: 'HOME',
        path: '/dashboard'
    },
    {
        label: 'Rewards',
        icon: REWARDS,
        priv: 'REWARDS',
        path: '/rewards'
    },
    {
        label: 'Account Settings',
        icon: SETTINGS,
        priv: 'SETTINGS',
        path: '/settings'
    },
    {
        label: 'Application Management',
        icon: SETTINGS,
        priv: 'APP_MANAGEMENT',
        path: '/application-management'
    },
    {
        label: 'Institutes',
        icon: SETTINGS,
        priv: 'INSTITUTES',
        path: '/institutes'
    },
    {
        label: 'Courses',
        icon: SETTINGS,
        priv: 'COURSES',
        path: '/courses'
    }
]