import React from "react";
import STEPPER_ONE from '../../assets/images/stepper-one.svg';
import STEPPER_TWO from '../../assets/images/stepper-two.svg';
import STEPPER_THREE from '../../assets/images/stepper-three.svg';
import { useMediaQuery } from "react-responsive";


const DraftGrid = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    });
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    return (
        <>
            <div className="col-12 md:col-2 lg:col-2 sm:col-12 p-0">
                <h2 className="font-l">Our Process</h2>
            </div>
            {isDesktopOrLaptop && <div className="col-12 md:col-10 lg:col-10 sm:col-12">
                <div className="option-1 option-1-1">
                    <ol className="c-stepper">
                        <li className="c-stepper__item c-stepper-bg-one">
                            <h3 className="c-stepper__title">
                                <img src={STEPPER_ONE} alt="Fill application details" className="c-stepper__title" />
                            </h3>
                            <p className="c-stepper__desc">Fill Application Details</p>
                        </li>
                        <li className="c-stepper__item c-stepper-bg-two">
                            <h3 className="c-stepper__title">
                                <img src={STEPPER_TWO} alt="Fill application details" className="c-stepper__title" />
                            </h3>
                            <p className="c-stepper__desc">Select EMI plan & Complete your KYC </p>
                        </li>
                        <li className="c-stepper__item c-stepper-bg-three">
                            <h3 className="c-stepper__title">
                                <img src={STEPPER_THREE} alt="Fill application details" className="c-stepper__title" />
                            </h3>
                            <p className="c-stepper__desc">Pay monthly EMI and Collect rewards</p>
                        </li>
                    </ol>
                </div>
            </div>}
            {isMobile && <div className="mb-8">
                <div class="step step-active">
                    <div>
                        <div class="circle-one"></div>
                    </div>
                    <div>
                        <img src={STEPPER_ONE} alt="Fill application details" className="c-stepper__title" />
                        <p className="p-0 c-stepper__desc">Fill Application Details</p>
                    </div>
                </div>
                <div class="step step-active">
                    <div>
                        <div class="circle-two"></div>
                    </div>
                    <div>
                        <img src={STEPPER_TWO} alt="Fill application details" className="c-stepper__title" />
                        <p className="p-0 c-stepper__desc">Select EMI plan & Complete your KYC </p>
                    </div>
                </div>
                <div class="step step-active">
                    <div>
                        <div class="circle-three"></div>
                    </div>
                    <div>
                        <img src={STEPPER_THREE} alt="Fill application details" className="c-stepper__title" />
                        <p className="p-0 c-stepper__desc">Pay monthly EMI and Collect rewards</p>
                    </div>
                </div>
            </div>
            }
        </>
    )
}

export default DraftGrid;