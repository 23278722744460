import React from "react";
import STATUS_IN_PROGRSS from '../../assets/images/in-progress.svg';
import STATUS_FILLED from '../../assets/images/status-filled.svg';
import './Application.scss';
import { useState } from "react";
import { useEffect } from "react";
import api from "../../api/services";
import { useNavigate } from "react-router-dom";

const FormBanner = () => {
    const [applicationStatus, setApplicationStatus] = useState({});
    const navigate = useNavigate();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    const trackApplicationStatus = () => {
        api.get('/applicationStatus/' + currentUser?.studentId).then(res => {
            if (res.status === 200) {
                setApplicationStatus(res.data.applicationStatus);
                localStorage.setItem('appStatus', JSON.stringify(res.data));
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
        })
    };

    useEffect(() => {
        if (currentUser?.studentId)
            trackApplicationStatus();
    }, [])

    return (
        <div className="col-12 stepper-grid">
            <div className='flex title'>
                Easy three step application
            </div>
            <ol className="b-stepper">
                <li className="b-stepper__item">
                    <img src={applicationStatus?.STUDENT_FORM === 'SUCCESS' ? STATUS_FILLED : STATUS_IN_PROGRSS} alt="in progress" className="mr-2" />
                    Enter Student Details
                </li>
                <li className="b-stepper__item">
                    <img src={applicationStatus?.KYC_FORM === 'SUCCESS' ? STATUS_FILLED : STATUS_IN_PROGRSS} alt="circle" className="mr-2" />
                    Complete KYC
                </li>
                <li className="b-stepper__item">
                    <img src={applicationStatus?.LOAN_PROCESS === 'APPROVED' ? STATUS_FILLED : STATUS_IN_PROGRSS} alt="in progress" className="mr-2" />
                    Loan Process
                </li>
            </ol>
        </div>
    )
}

export default FormBanner;