import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import './Login.scss';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import { classNames } from 'primereact/utils';
import { Checkbox } from 'primereact/checkbox';
import * as Yup from 'yup';
import { Toast } from "primereact/toast";
import api from "../../api/services";
import { ProgressSpinner } from "primereact/progressspinner";
import { useMediaQuery } from "react-responsive";

import { Slider } from 'primereact/slider';

const PasswordLogin = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    });
    const [value, setValue] = useState(null);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const toast = useRef(null);
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);

    const mobileRegExp = /^\d{10}$/;
    const LoginSchema = Yup.object().shape({
        phone: Yup
            .string()
            .required('Please Enter your Mobile Number')
            .matches(
                mobileRegExp,
                "Mobile Number is not valid"
            ),
        password: Yup
            .string()
            .required('Please Enter your password')
            .matches(
                mobileRegExp,
                "Password is not valid"
            )
    });

    const formik = useFormik({
        initialValues: {
            phone: '',
            password: ''
        },
        validate: (data) => {
            let errors = {};
            if (!data.phone) {
                errors.phone = 'Mobile number is required.';
            }
            if (!data.password) {
                errors.password = 'Password is required.';
            }
            return errors;
        },
        validationSchema: LoginSchema,

        onSubmit: (values) => {
            setLoading(true);
            if (!checked) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please do check Preksha terms and conditions' });
                setLoading(false);
                return;
            }
            doLogin(values);
        },
    });

    const doLogin = (values) => {

    }

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    return (
        <form onSubmit={formik.handleSubmit} className="login-form">
            {isDesktopOrLaptop && <p className="login-title text-4xl font-semi mt-8 pt-8 mb-1">Welcome to Preksha</p>}
            {isMobile && <p className="login-title text-2xl text-center mb-1 mt-0">Welcome to Preksha</p>}
            <div className="flex flex-column mt-4">
                {loading && <ProgressSpinner />}
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon addon">
                        + 91 -
                    </span>
                    <InputText placeholder="Enter your 10 digit mobile number" id="phone"
                        name="phone"
                        className={`login-input ${classNames({ 'p-invalid ': isFormFieldInvalid('phone') })}`}
                        type="phone" {...formik.getFieldProps('phone')} />
                </div>
                {formik.touched.phone && formik.errors.phone ? (
                    <div className="p-invalid">{formik.errors.phone}</div>
                ) : null}
            </div>
            <div className="flex flex-column mt-4">
                <div className="p-inputgroup">
                    <InputText placeholder="Enter your password" id="password"
                        name="password"
                        className={`login-input ${classNames({ 'p-invalid ': isFormFieldInvalid('password') })}`}
                        type="password" {...formik.getFieldProps('password')} />
                </div>
                {formik.touched.password && formik.errors.password ? (
                    <div className="p-invalid">{formik.errors.password}</div>
                ) : null}
            </div>
            <div className="flex flex-column mt-4">
                <Button type="submit" label="Proceed to Application" className="w-full login-button" disabled={!formik.isValid || loading} />
            </div>
            <div className="flex align-items-center mt-4">
                <Checkbox onChange={e => setChecked(e.checked)} checked={checked} />
                <span className="ml-2 text-base check-label font-l">
                    By signing in, I agree to Preksha’s&nbsp;<a htmlFor="agreeCheck" href="https://prekshaedu.com/terms.html" target="blank">Terms and Conditions</a>&nbsp;and&nbsp;<a htmlFor="agreeCheck" href="https://prekshaedu.com/privacy.html" target="blank">
                        Privacy Policy
                    </a>
                </span>
            </div>
            <Slider value={value} onChange={(e) => setValue(e.value)} />
            <Toast ref={toast} />

        </form >
    )
}


export default PasswordLogin;