import React from "react";
import { useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import AddInstitute from "./AddInstitute";
import { useEffect } from "react";
import api from "../../api/services";
import { useNavigate } from "react-router-dom";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useRef } from "react";
import { Toast } from 'primereact/toast';
import { InputSwitch } from 'primereact/inputswitch';
import AddBoard from "./AddBoard";



const Institutes = () => {
    const toast = useRef(null);
    const navigate = useNavigate();
    const [institutes, setInstitutes] = useState([]);
    const [visible, setVisible] = useState(false);
    const [visibleBoardPopup, setVisibleBoardPopup] = useState(false);
    const [loading, setLoading] = useState(true);
    const [record, setRecord] = useState(null);
    const [action, setAction] = useState('Add');
    const [checked, setChecked] = useState(true);
    const [filters, setFilters] = useState({
        instituteName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        acdemicYear: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        state: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        interestRate: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        location: { value: null, matchMode: FilterMatchMode.STARTS_WITH }
    });

    useEffect(() => {
        getMeInstituteList();
    }, []);

    const updateInstitute = (value, status) => {
        const body = { ...record };
        body.status = status;
        api.put('/institute/updateInstitute/' + value._id, body).then(res => {
            if (res.status === 200 && res.data?.result !== null) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Institute updated successfully' });
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res?.data.message });
            }
            getMeInstituteList();
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
            setLoading(false);
        });
    };

    const getMeInstituteList = () => {
        api.get('/institute/getAllInstitutes').then(response => {
            console.log('response', response);
            if (response.status === 200) {
                setInstitutes(response?.data?.data);
            }
            setLoading(false);
            setVisible(false);
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            setLoading(false);
            setVisible(false);

        })
    };
    const getMeStatus = (status) => {
        return status === 'Active';
    }

    const setActions = (value) => {
        return <div className="flex justify-content-center">
            <i className="pi pi-pencil pointer mr-2" onClick={() => onClickEdit(value)}></i>
            <i className="pi pi-trash pointer mr-2" onClick={() => deleteInstitute(value)}></i>
            <InputSwitch checked={getMeStatus(value.status)} onChange={(e) => {
                setChecked(e.value); setRecord(value); let status;
                if (e.value) {
                    status = 'Active';
                } else {
                    status = 'Inactive';
                }
                setLoading(true);
                updateInstitute(value, status)
            }} />
        </div>
    }

    const onClickAddBoard = (value) => {
        console.log('>>>>', value);
        setVisibleBoardPopup(true);
        setRecord(value);
    }
    const getMeBoards = (value) => {
        return <div className="flex justify-content-center">
            {/* {value?.boards && <p className="boards">{value?.boards.join(',')}</p>} */}
            <i className="pi pi-info-circle pointer" style={{ fontSize: '1rem' }} title="Click here to see boards" onClick={() => onClickAddBoard(value)}></i>
            {/* < i className="pi pi-plus pointer flex justify-content-center align-items-center" title="Add Board"></i> */}

        </div >
    }

    const onClickEdit = (value) => {
        setVisible(true);
        setRecord(value);
        setAction('Edit');
    }

    const hidePopup = () => {
        // setAction(null);
        getMeInstituteList();
    }

    const deleteInstitute = (value) => {
        setLoading(true);
        api.delete('/institute/deleteInstitute/' + value._id).then(res => {
            if (res.status === 200 && res.data?.result !== null) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Institute deleted successfully' });
                getMeInstituteList();
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res?.data.message });
            }
            setLoading(false);
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
            setLoading(false);
        });
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex align-items-center justify-content-center">
                        <p className="page-title mb-0">Institutes</p>
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <Button label="Add" onClick={() => {
                            navigate('/institute/add');
                        }} />
                    </div>
                </div>
            </div>
            {institutes && <div className="col-12">
                <DataTable value={institutes} dataKey="id" filters={filters} filterDisplay="row" loading={loading} paginator rows={10} emptyMessage="No customers found." globalFilterFields={['instituteName', 'acdemicYear', 'state', 'status', 'interestRate', 'location']} >
                    <Column field="instituteName" header="Name" filter filterPlaceholder="Search by name"></Column>
                    <Column field="state" header="State" filter filterPlaceholder="Search by State"></Column>
                    <Column field="status" header="Status" filter filterPlaceholder="Search by Status"></Column>
                    <Column field="interestRate" header="Intrest Rate" filter filterPlaceholder="Search by Intrest rate"></Column>
                    <Column field="location" header="Location" filter filterPlaceholder="Search by location"></Column>
                    <Column field="board" header="Boards" body={(e) => getMeBoards(e)}></Column>
                    <Column field="" header="Actions" body={(e) => setActions(e)}>

                    </Column>

                </DataTable>
            </div>}
            {visible && <AddInstitute visible={visible} hidePopup={hidePopup} getMeInstituteList={getMeInstituteList} record={record} action={action} />}
            {visibleBoardPopup && <AddBoard visible={visibleBoardPopup} hidePopup={() => { setVisibleBoardPopup(false); getMeInstituteList() }} record={record} />}
            <Toast ref={toast} />
        </div>
    )
}


export default Institutes;