import React, { useState, useRef } from "react";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from 'primereact/button';
import { Checkbox } from "primereact/checkbox";
import { Calendar } from 'primereact/calendar';
import { useFormik } from "formik";
import { Toast } from 'primereact/toast';
import { KycSchema } from "../../schemas";
import OtpPopup from "../../components/otpPopup/OtpPopup";
import api from "../../api/services";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect } from "react";
import axios from "axios";
import FormBanner from "./FormBanner";
import { useMediaQuery } from "react-responsive";
import ProcessPopup from "../../components/processPopup/ProcessPopup";

const initialValues = {
    pan: '',
    fullName: '',
    lastName: '',
    phone: '',
    email: '',
    dob: '',
    maritalStatus: '',
    gender: '',
    employmentType: '',
    yoe: '',
    address: '',
    pincode: '',
    state: '',
    city: '',
    bankCode: '',
    accountNo: ''
}

const KycForm = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    });
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const navigate = useNavigate();
    const location = useLocation();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [visible, setVisible] = useState(false);
    const [parentInfo, setParentInfo] = useState(null);
    const [uploadRefId, setUploadRefId] = useState(null);
    const [showProcessPopup, setShowProcessPopup] = useState(false);
    const [referenceId, setReferenceId] = useState(null)
    const [termsCheck, setTermsCheck] = useState(false);
    const [banks, setBanks] = useState([]);
    const [btnAction, setBtnAction] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    let studentDetails;
    if (location.state) {
        studentDetails = location.state;
        delete studentDetails.__v;
    }
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);


    useEffect(() => {
        if (currentUser.phone)
            getParentDetails();
        getBankList();
    }, []);

    const getParentDetails = () => {
        api.get('/getParentInfo/' + currentUser.phone).then(res => {
            if (res.status === 200 && res.data?.result.length > 0) {
                res.data.result[0]['dob'] = new Date(res.data?.result[0]?.dob);
                setParentInfo(res.data?.result[0]);
                const loginUser = { ...currentUser, ...res.data?.result[0] };
                localStorage.setItem('currentUser', JSON.stringify(loginUser));
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
        })
    };

    const getBankList = () => {
        api.get('/banks').then(res => {
            if (res.status === 200 && res.data) {
                setBanks(res.data);
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.statusText });
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
        })
    };


    const { values, errors, touched, handleBlur, handleChange, handleSubmit, getFieldProps } = useFormik({
        initialValues: parentInfo || initialValues,
        validationSchema: KycSchema,
        enableReinitialize: true,
        onSubmit: () => {
            if (termsCheck && btnAction === 'SUBMIT') {
                if (uploadRefId === null) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please upload Bank Statement' });
                    return
                }
                getOtp()
            } else if (!termsCheck && btnAction === 'SUBMIT') {
                toast.current.show({ severity: 'error', summary: 'Error', detail: "Please indicate that you accept the Terms and Conditions" });
            }
            setBtnAction('');
        }
    });

    const saveParentDetails = () => {
        let body = { ...values };
        body["studentId"] = currentUser.studentId;
        body["scoremeBSA"] = uploadRefId;
        api.post('/parent/addParent', body).then(res => {
            if (res.status === 200 && res.data?.result !== null) {
                let data = res.data?.result;
                data['dob'] = new Date(data?.dob);
                const loginUser = { ...currentUser, ...data };
                localStorage.setItem('currentUser', JSON.stringify(loginUser));
                setParentInfo(data);
                setVisible(false);
                setShowProcessPopup(true)
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Parent Info saved successfully' });
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res?.data.message });
            }
            setLoading(false);
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
            setLoading(false);
        });
    };

    const verifyPan = (data) => {
        const dob = new Date(data?.dob);
        api.post('/scoreme/panVerify/' + data.phone, {
            "panNumber": data.pan,
            "fullName": data.fullName,
            "status": "INDIVIDUAL",
            "dateOfBirth": ('0' + dob.getDate()).slice(-2) + '-' + dob.toLocaleString('default', { month: 'short' }).toUpperCase() + '-' + dob.getFullYear(),
            "studentId": currentUser.studentId
        }).then(res => {
            if (res.status === 200) {
                getOtp();
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.message });
                setLoading(false);
            }
        }).catch(err => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
            setLoading(false);
        })
    };


    const getOtp = () => {
        let obj = { ...values };
        const body = {
            "bureauName": ["equifax"],
            "middleName": "",
            "lastName": obj?.fullName.split(' ')[0],
            "firstName": obj?.fullName.split(' ')[1],
            "addressList": [{ "address": "gayatri nagar, madhapur ,hyderabad", "state": "TG", "pinCode": "500018" }],
            "mobileList": [obj.phone.toString()],
            "identityList": [obj.pan],
            "dateOfBirth": obj.dob.getFullYear() + '-' + ("0" + (obj.dob.getMonth() + 1)).slice(-2) + '-' + ("0" + obj.dob.getDate()).slice(-2),
            "gender": obj.gender,
            // "studentId": currentUser.studentId
        };
        api.post('/scoreme/EquifaxReport/?=' + currentUser.studentId, body).then(res => {
            if (res.status === 200 && res.data.result.data !== null) {
                setReferenceId(res.data.result.data.referenceId)
                toast.current.show({ severity: 'success', summary: 'SUCCESS', detail: res.data.result.responseMessage });
                setVisible(true);
            } else {
                toast.current.show({ severity: 'error', summary: 'ERROR', detail: res.data.message });
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
        })
    }
    const onChangeFile = event => {
        setSelectedFile(event.target.files[0]);
    };

    const customBase64Uploader = async (event) => {
        setLoading(true);
        // convert file to base64 encoded
        const file = selectedFile;
        const formData = new FormData();
        const bankDetails = {
            accountNumber: values.accountNo,
            bankCode: values.bankCode,
            accountType: 'savings',
            entityType: 'Individual'
        }
        formData.append('data', JSON.stringify(bankDetails));
        formData.append('file', file);
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://sm-bsa.scoreme.in/bsa/external/uploadbankstatement',
            headers: {
                'clientId': '3ae655d05f8696c0787763da4f90255d',
                'clientSecret': '00028c861f5ea259fb47891ce606f5d35d4fb70c9ecee9174d1b786ced2cf252',
            },
            data: formData
        };

        axios.request(config)
            .then((response) => {
                setLoading(false);
                if (response.data.data === null) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.responseMessage });
                } else {
                    setUploadRefId(response.data.data.referenceId);
                    toast.current.show({ severity: 'success', summary: 'SUCCESS', detail: response.data.responseMessage });
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate('/otp-login');
                    return;
                }
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
            });
    };



    return (
        <div className={loading ? 'mask grid' : 'grid'} >
            <div className={isDesktopOrLaptop ? "form-layout-desktop" : "form-layout-mobile"}>
                {loading ? <ProgressSpinner className="absolute top-50 left-50" /> : ''}
                <form onSubmit={handleSubmit} className="login-form">
                    <div className="col-12 p-5 border-gray-400 border-1 border-round mb-5">
                        <p className="text-xl text-primary">Enter KYC Details</p>
                        <div className="grid">
                            <div className="col-12 md:col-12 lg:col-12">
                                <div className="field">
                                    <label htmlFor="pan" className="text-regular font-l">PAN Card no</label>
                                    <InputText id="pan" value={values.pan} className=" w-full" placeholder="Enter PAN Number" onBlur={handleBlur} onChange={handleChange} disabled={parentInfo !== null} />
                                    {touched.pan && errors.pan ? (
                                        <div className="p-invalid">{errors.pan}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="flex justify-content-center flex-wrap card-container yellow-container">
                                <b className="text-regular font-l">Please Note: </b>
                                <p className="m-0 text-regular font-l">Your Credit Score will not be impacted by this.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 p-4 border-gray-400 border-1 border-round mb-3">
                        <p className="text-xl text-primary">Enter Parent Details</p>
                        <div className="grid">
                            <div className="col-12 md:col-6 lg:col-6">
                                <div className="field">
                                    <label htmlFor="fullName" className="text-regular font-l">Full Name as per PAN</label>
                                    <InputText id="fullName" placeholder="Enter full name" value={values.fullName} className=" w-full" onBlur={handleBlur} onChange={handleChange} disabled={parentInfo !== null} />
                                    {touched.fullName && errors.fullName ? (
                                        <div className="p-invalid">{errors.fullName}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-6">
                                <div className="field">
                                    <label htmlFor="phone" className="text-regular font-l">Phone Number</label>
                                    <InputText id="phone" placeholder="Banking phone number" value={values.phone} className=" w-full" onBlur={handleBlur} onChange={handleChange} disabled={parentInfo !== null} />
                                    {touched.phone && errors.phone ? (
                                        <div className="p-invalid">{errors.phone}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-6">
                                <div className="field">
                                    <label htmlFor="email" className="text-regular font-l">Email</label>
                                    <InputText id="email" placeholder="Enter email" value={values.email} className=" w-full" onBlur={handleBlur} onChange={handleChange} disabled={parentInfo !== null} />
                                    {touched.email && errors.email ? (
                                        <div className="p-invalid">{errors.email}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-6">
                                <div className="flex-auto field">
                                    <label htmlFor="calendar-12h" className="text-regular font-l">
                                        Date of Birth
                                    </label>
                                    <Calendar value={values.dob} showIcon className="w-full" placeholder="dd/mm/yyyy" dateFormat="dd/mm/yy" onBlur={handleBlur} onChange={handleChange} {...getFieldProps('dob')} disabled={parentInfo !== null} />
                                    {touched.dob && errors.dob ? (
                                        <div className="p-invalid">{errors.dob}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-6">
                                <div className="field">
                                    <label htmlFor="maritalStatus" className="text-regular font-l">Marital Status</label>
                                    <Dropdown value={values.maritalStatus} options={[{ label: 'Married', value: 'married' }, { label: 'Single', value: 'single' }, { label: 'Divorce', value: 'divorce' }, { label: 'Widower', value: 'widower' }]} optionValue="value" optionLabel="label" placeholder="Select Marital Status" className="w-full" onBlur={handleBlur} onChange={handleChange}
                                        {...getFieldProps('maritalStatus')} disabled={parentInfo !== null} />
                                    {touched.maritalStatus && errors.maritalStatus ? (
                                        <div className="p-invalid">{errors.maritalStatus}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-6">
                                <div className="field">
                                    <label htmlFor="gender" className="text-regular font-l">Gender</label>
                                    <Dropdown value={values.gender} options={[{ label: 'Male', value: 'M' }, { label: 'Female', value: 'F' }]} optionLabel="label" optionValue="value" placeholder="Select Gender" className="w-full" onBlur={handleBlur} onChange={handleChange}
                                        {...getFieldProps('gender')} disabled={parentInfo !== null} />
                                    {touched.gender && errors.gender ? (
                                        <div className="p-invalid">{errors.gender}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-6">
                                <div className="field">
                                    <label htmlFor="employmentType" className="text-regular font-l">Employment Type</label>
                                    <Dropdown value={values.employmentType} options={[{ label: 'Salaried', value: 'Salaried' }, { label: 'Self Employed', value: 'Self Employed' }, { label: 'Business', value: 'Business' }]} optionLabel="label" optionValue="value" placeholder="Select Employment Type" className="w-full" onBlur={handleBlur} onChange={handleChange}
                                        {...getFieldProps('employmentType')} disabled={parentInfo !== null} />
                                    {touched.employmentType && errors.employmentType ? (
                                        <div className="p-invalid">{errors.employmentType}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-6">
                                <div className="field">
                                    <label htmlFor="yoe" className="text-regular font-l">Years of Experience (At present company)</label>
                                    <InputText id="yoe" placeholder="Enter in no. of years" value={values.yoe} className=" w-full" onBlur={handleBlur} onChange={handleChange} disabled={parentInfo !== null} />
                                    {touched.yoe && errors.yoe ? (
                                        <div className="p-invalid">{errors.yoe}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-6">
                                <div className="field">
                                    <label htmlFor="address" className="text-regular font-l">Address</label>
                                    <InputText id="address" placeholder="Enter Your Address" value={values.address} className=" w-full" onBlur={handleBlur} onChange={handleChange} disabled={parentInfo !== null} />
                                    {touched.address && errors.address ? (
                                        <div className="p-invalid">{errors.address}</div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 p-5 border-gray-400 border-1 border-round mb-5">
                        <p className="text-xl text-primary">{parentInfo === null ? 'Upload Documnets' : 'Bank Details'}</p>
                        <div className="grid">
                            <div className="col-12 md:col-6 lg:col-6">
                                <div className="field">
                                    <label htmlFor="bankCode" className="text-regular font-l">Name of the Bank</label>
                                    <Dropdown value={values.bankCode} filter options={banks} optionValue="bankCode" optionLabel="bankName" placeholder="Select Bank" className="w-full" onBlur={handleBlur} onChange={handleChange}
                                        {...getFieldProps('bankCode')} disabled={parentInfo !== null} />
                                    {touched.bankCode && errors.bankCode ? (
                                        <div className="p-invalid">{errors.bankCode}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-6">
                                <div className="field">
                                    <label htmlFor="accountNo" className="text-regular font-l">Account Number</label>
                                    <InputText id="accountNo" placeholder="Enter Account Number" value={values.accountNo} className=" w-full" onBlur={handleBlur} onChange={handleChange} disabled={parentInfo !== null} />
                                    {touched.accountNo && errors.accountNo ? (
                                        <div className="p-invalid">{errors.accountNo}</div>
                                    ) : null}
                                </div>
                            </div>
                        </div>


                        {parentInfo === null && <div className="mt-4 border-gray-400 border-1 border-round  p-4">
                            <div className="mb-3 upload-input">
                                <input type="file" onChange={(e) => onChangeFile(e)} accept=".pdf" />

                            </div>
                            <div>
                                <Button label="Upload" disabled={selectedFile === null} onClick={() => { customBase64Uploader() }} />

                            </div>
                            {/* <FileUpload className="w-full" name="demo[]" url={'/api/upload'} accept=".pdf" maxFileSize={1000000} customUpload uploadHandler={customBase64Uploader} /> */}
                        </div>}
                        {parentInfo === null && <div className="flex justify-content-center flex-wrap card-container yellow-container">
                            <p className="font-l text-sm">Please upload bank statement for last 3 months </p>
                        </div>}
                    </div>
                    <div className="col-12 pl-4 pr-4 md:col-12 lg:col-12">
                        <div className="flex align-items-center mt-3">
                            <Checkbox checked={termsCheck || parentInfo !== null} onChange={e => setTermsCheck(e.checked)} />
                            <label htmlFor="agreeCheck" className="ml-2 text-base check-label font-l">I hereby authorize
                                Preksha Edutech/NBFC lending partners to obtain my Credit Bureau reports and I also accept the Terms and Conditions and Privacy Policy of Preksha Edutech</label>
                        </div>
                    </div>
                    <div className="col-12 p-4">
                        <Button label="Back" className="p-button-outlined" onClick={() => { navigate('/application-form-one'); }} />
                        <Button label="Validate" className="float-right ml-2" type="submit" onClick={() => setBtnAction('SUBMIT')} />
                    </div>
                    <OtpPopup visible={visible} hidePop={() => setVisible(false)} referenceId={referenceId} phone={parentInfo?.phone} saveParent={saveParentDetails} />
                    <Toast ref={toast} />
                    {showProcessPopup && <ProcessPopup visible={true} title="Hang tight!" subTitle="Our gnomes are working hard on your loan application relax and check back later!"
                        buttonLabel="Check Status" buttonType="primary" footerContent="Please note: your previous data is saved with us" />}
                </form>
            </div>
        </div>
    )
}

export default KycForm;