import { Sidebar } from "primereact/sidebar";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LOGOUT from '../../assets/images/logout.png';
import { MENU_ITEMS } from "../../constants/MenuItems";
import LOGO from '../../assets/preksha-logo.svg';
import './MobileHeader.scss';

const MobileHeader = () => {
    const location = useLocation();
    const menu = useRef(null);
    const navigate = useNavigate();
    const [visibleRight, setVisibleRight] = useState(false);
    const currentUser = localStorage.getItem('currentUser');
    const items = [
        {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: () => {
                localStorage.clear();
                navigate('/otp-login');
            }
        },
    ];
    return (
        <div class="flex justify-content-between flex-wrap pt-2 pb-2 header">
            <div class="flex align-items-center justify-content-center">
                <img src={LOGO} alt="logo" />
            </div>
            <div class="flex align-items-center justify-content-center">
                <i className="pi pi-align-right" style={{ fontSize: '1.5rem' }} onClick={() => setVisibleRight(true)}></i>
                <Sidebar visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                    {MENU_ITEMS.map((item, index) => <div key={index} className={`flex align-items-center cursor-pointer menu-item ${location.pathname === item.path ? " menu-item-active" : ""}`}
                        onClick={() => { navigate(item.path); setVisibleRight(false); }}>
                        <img src={item.icon} alt={item.label} className="mr-2" />
                        {item.label}
                    </div >)}
                    <div className='flex align-items-center cursor-pointer menu-item ' onClick={() => { localStorage.clear(); setVisibleRight(false); navigate('/otp-login'); }}>
                        <img src={LOGOUT} alt="logout" className="mr-2" />
                        Logout
                    </div >
                </Sidebar>
            </div>
        </div>
    )
}

export default MobileHeader;