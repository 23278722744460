import React, { useState, useRef, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import { useFormik } from "formik";
import { CourseFormSchema } from "../../schemas";
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import api from "../../api/services";
import { useNavigate } from "react-router-dom";
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';


const AddCourse = (props) => {
    const { visible, record, action, filter, type, courses } = props;
    const navigate = useNavigate();
    const toast = useRef(null);
    const validationSchema = CourseFormSchema;
    const [institutes, setInstitutes] = useState([]);
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: action === 'Add' ? {
            instituteName: filter.instituteName,
            acdemicYear: filter.acdemicYear,
            courseName: filter.courseName,
            class: '',
            coursefee: '',
            state: filter.location,
        } : {
            instituteName: filter.instituteName,
            acdemicYear: record !== null ? record.acdemicYear : filter.acdemicYear,
            courseName: record !== null ? record.courseName : filter.courseName,
            class: record !== null ? record.class : '',
            coursefee: record !== null ? record.coursefee.replace(/,/g, "") : '',
            state: filter.location,
        },
        validationSchema,
        onSubmit: (data) => {
            if (action === 'Add')
                saveCourse(data);
            else
                updateCourse(data);
        },
    });

    useEffect(() => {
        getMeInstituteList();
    }, []);

    const getMeInstituteList = () => {
        api.get('/institute').then(response => {
            if (response.status === 200) {
                setInstitutes(response.data);
            }
            setLoading(false);
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            setLoading(false);
        })
    };

    const getMeInstId = (inst) => {
        console.log('institutes', institutes);
        return institutes.filter(obj => { return obj.instituteName === inst })[0]._id
    }
    const getMeBoardId = (board) => {
        console.log('courses', courses);
        return courses.filter(obj => { return obj.boardName === board })[0]._id
    }

    const saveCourse = (data) => {
        data.acdemicYear = new Date(data.acdemicYear[0]).getFullYear() + "-" + new Date(data.acdemicYear[1]).getFullYear();
        data.boardId = getMeBoardId(data.courseName);
        data.instituteId = getMeInstId(data.instituteName);
        api.post('/course/addCourse', data).then(res => {
            if (res.status === 200 && res.data?.result !== null) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Course added successfully' });
                setTimeout(() => {
                    props.hidePopup();
                }, 1000);
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res?.data?.message });
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
            setLoading(false);
        });
    }

    const updateCourse = (data) => {
        data.acdemicYear = new Date(data.acdemicYear[0]).getFullYear() + "-" + new Date(data.acdemicYear[1]).getFullYear();
        data.boardId = getMeBoardId(data.courseName);
        data.instituteId = getMeInstId(data.instituteName);
        setLoading(true);
        api.put('/course/updateCourse/' + record._id, data).then(res => {
            if (res.status === 200 && res.data?.result !== null) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Course updated successfully' });
                setTimeout(() => {
                    props.hidePopup();
                }, 1000);
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res?.data?.message });
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
            setLoading(false);
        });
    }

    const isFormFieldInvalid = (name) => formik.touched[name] && formik.errors[name];
    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };
    const getMeHeader = () => {
        return action + " " + "Course";
    }

    return (
        <Dialog header={getMeHeader()} visible={visible} style={{ width: '50vw' }} onHide={() => props.hidePopup()} >
            <form onSubmit={formik.handleSubmit}  >
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6">
                        <div className="field">
                            <label htmlFor="calendar-12h" className="block mb-2">
                                Institute
                            </label>
                            <Dropdown disabled={type === 'course'} value={formik.values.instituteName} options={institutes} optionLabel="instituteName" optionValue="instituteName" onChange={(e) => {
                                formik.setValues({
                                    ...formik.values,
                                    instituteName: e.target.value,
                                    acdemicYear: "2023-2034"
                                })
                            }}
                                placeholder="Select Institute"
                                className={classNames(isFormFieldInvalid('instituteName') ? 'p-invalid w-full' : 'w-full')} />
                            {getFormErrorMessage('instituteName')}
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <div className="field">
                            <label htmlFor="state" className="text-regular font-l">Location</label>
                            <InputText disabled={type === 'course'} value={formik.values.state} onChange={(e) => {
                                formik.setFieldValue('state', e.target.value);
                            }} placeholder="Enter Location" className={classNames(isFormFieldInvalid('state') ? 'p-invalid w-full' : 'w-full')} />
                            {getFormErrorMessage('state')}
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <div className="field">
                            <label htmlFor="calendar-12h" className="block mb-2">
                                Board
                            </label>
                            <Dropdown name="Board" onChange={(e) => {
                                formik.setFieldValue('courseName', e.target.value);
                            }} value={formik.values.courseName} options={courses} optionLabel="boardName" optionValue="boardName" placeholder="Select Board" className="w-full" />

                            {getFormErrorMessage('courseName')}
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <div className="field">
                            <label htmlFor="calendar-12h" className="block mb-2">
                                Academic Year
                            </label>
                            <Dropdown name="Academic Year" onChange={(e) => {
                                formik.setFieldValue('acdemicYear', e.target.value);
                            }} value={formik.values.acdemicYear} options={[{ label: '2022-2023' }, { label: '2023-2024' }, { label: '2024-2025' }]} optionLabel="label" optionValue="label" placeholder="Select  Academic Year" className="w-full" />

                            {getFormErrorMessage('acdemicYear')}
                        </div>
                    </div>


                    <div className="col-12 md:col-6 lg:col-6">
                        <div className="field">
                            <label htmlFor="class" className="text-regular font-l">Class</label>
                            <InputText value={formik.values.class} onChange={(e) => {
                                formik.setFieldValue('class', e.target.value);
                            }}
                                placeholder="Enter Class"
                                className={classNames(isFormFieldInvalid('class') ? 'p-invalid w-full' : 'w-full')} />
                            {getFormErrorMessage('class')}
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <div className="field">
                            <label htmlFor="coursefee" className="text-regular font-l">Course Fee</label>
                            <InputText value={formik.values.coursefee} onChange={(e) => {
                                formik.setFieldValue('coursefee', e.target.value);
                            }}
                                placeholder="Enter Course Fee"
                                className={classNames(isFormFieldInvalid('coursefee') ? 'p-invalid w-full' : 'w-full')} />
                            {getFormErrorMessage('coursefee')}
                        </div>
                    </div>
                </div>
                <Button label="Save" type="submit" />
            </form>
            <Toast ref={toast} />

        </Dialog >
    )
}

export default AddCourse;