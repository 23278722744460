import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { Card } from 'primereact/card';
import LOGOUT from '../../assets/images/logout.png';
import NO_USER from '../../assets/user.png';
// import MENU_DECOR from '../../assets/images/menu_decor.svg';
// import WHATSAPP_CARD from '../../assets/images/whatsapp_card.svg';
import LOGO from '../../assets/preksha-logo.svg';
import { MENU_ITEMS } from "../../constants/MenuItems";
import './SideMenu.scss';
// import { InputText } from "primereact/inputtext";
// import { Button } from "primereact/button";
import { useEffect } from "react";
import { useState } from "react";
import { PRIVS } from "../../privileges";



const SideMenu = () => {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const navigate = useNavigate();
    const location = useLocation();
    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        let appStatus = JSON.parse(localStorage.getItem('appStatus'));
        let loanStatus = appStatus?.loanStatus?.status;
        let list = [];
        if (currentUser.phone === "8106677301" || currentUser.phone === "7995522253" || currentUser.phone === '9618398198' || currentUser.phone === '9533535511' || currentUser.phone === '8686889337' || currentUser.phone === "9701456646" || currentUser.phone === "9490442662" || currentUser.phone === "9618398198") {
            let privs = PRIVS.ADMIN;
            privs.forEach(element => {
                MENU_ITEMS.forEach(obj => {
                    if (obj.priv === element)
                        list.push(obj)
                })
            });
        } else {
            let privs = PRIVS.PARENT;
            privs.forEach(element => {
                MENU_ITEMS.forEach(obj => {
                    if (obj.priv === element)
                        list.push(obj)
                })
            });
        }
        let items = [...list];
        if (loanStatus !== 'APPROVED') {
            items = list.filter(obj => { return obj.priv !== "REWARDS" });
        }
        if (appStatus === null || appStatus?.applicationStatus?.KYC_FORM !== 'SUCCESS') {
            items = list.filter(obj => { return obj.priv !== "SETTINGS" });
        }
        setMenuItems(items);
    }, []);

    const logout = () => {
        localStorage.clear();
        navigate('/otp-login');
    };

    const onClickMenuItem = (item) => {
        if (item.label === 'Home' && currentUser.userType === 'New') {
            navigate('/new-user-home');
        } else {
            navigate(item.path);
        }
    }

    return (
        <>
            <div className='flex justify-content-center align-items-center '>
                <img src={LOGO} alt="logo" className="mt-4" />
            </div>
            <div className='flex justify-content-center align-items-center mt-6'>
                <img src={NO_USER} alt="no user" className="mt-4" />
            </div >
            <div className='flex justify-content-center align-items-center '>
                <p>{currentUser?.fullName}</p>
            </div >
            {menuItems?.map((item, index) => <div key={index} className={`flex align-items-center cursor-pointer menu-item ${location.pathname === item.path ? " menu-item-active" : ""}`}
                onClick={() => { onClickMenuItem(item) }}>
                <img src={item.icon} alt={item.label} className="mr-2" />
                {item.label}
            </div >)}
            <div className='flex align-items-center cursor-pointer menu-item ' onClick={() => logout()}>
                <img src={LOGOUT} alt="logout" className="mr-2" />
                Logout
            </div >
            {/* Temporary Commented */}
            {/* <Card className="relative m-2 whatsapp-card mt-4">
                <img src={WHATSAPP_CARD} alt="test" className="absolute right-0" />
                <div className="w-100 p-3">
                    <div className="mb-2 w-8 mb-4 font-semi">
                        Get Updates on Whatsapp
                    </div>
                    <div className="p-inputgroup">
                        <InputText placeholder="Enter your whatsapp number" />
                        <Button icon="pi pi-arrow-right" className="p-button-success" />
                    </div>
                </div>
            </Card> */}
        </>
    )
}

export default SideMenu;