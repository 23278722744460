import React from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from "react-router-dom";

const RejectPopup = (props) => {
    const navigate = useNavigate();
    const { visible } = props;
    const footerContent = () => {
        return (
            <>
                <Button label="Okay" size="small" onClick={() => { localStorage.clear(); props.setVisible(false); navigate('/otp-login'); }} />
            </>
        )
    };
    return (
        <div className="card flex justify-content-center">
            <Dialog header="Application Status" visible={visible} onHide={() => { props.setVisible(false) }} footer={footerContent} closeOnEscape>
                <p className="m-0">
                    Your application got rejected. Please try after 30 days.
                </p>
            </Dialog>
        </div>
    )
}

export default RejectPopup;