import axios from "axios";
//const BASE_URL = 'https://api-preksha-dev.cyclic.app/api';
//const BASE_URL = 'http://preksha-api-ebs-dev.eba-wvfip2dh.ap-south-1.elasticbeanstalk.com/api';
const BASE_URL = 'https://parentdevapi.prekshaedu.com/api';

const instance = axios.create({
    baseURL: BASE_URL,
});

instance.interceptors.request.use(
    async (config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response?.status === 401) {
            localStorage.clear();
            window.location.href = '/otp-login';
        }
        return Promise.reject(error);
    }
);

export default instance;

