import React, { useRef } from "react";
import { useFormik } from "formik";
import { BankDetailsSchema } from "../../schemas";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { useEffect } from "react";
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Slider } from 'primereact/slider';




const BankDetails = () => {
    const toast = useRef(null);
    const action = 'Add';
    const record = null
    const validationSchema = BankDetailsSchema;
    useEffect(() => {
        if (record?.acdemicYear) {
            record['acdemicYear'] = [new Date(record.acdemicYear[0].split('-')[0]), new Date(record.acdemicYear[0].split('-')[1])]
        }
    }, [record])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            beneficiaryName: '',
            accountNumber: '',
            ifsc: '',
            location: '',
            state: '',
            workingModel: '',
            costToParent: '',
            hybrid: '',
            subventionInterestRate: '',
            costToParentInterestRate: '',
            instituteContribution: '',
            parentContribution: '',

            emi: 3
        },
        validationSchema,
        onSubmit: (data) => {
            if (action === 'Add') {

            }
        },
    });

    const isFormFieldInvalid = (name) => formik.touched[name] && formik.errors[name];
    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    return (
        <form onSubmit={formik.handleSubmit}  >
            <div className="grid">
                <div className="col-12 md:col-6 lg:col-6">
                    <div className="field">
                        <label htmlFor="location" className="text-regular font-l">Location</label>
                        <InputText value={formik.values.location} onChange={(e) => {
                            formik.setFieldValue('location', e.target.value);
                        }} placeholder="Enter Location" className={classNames(isFormFieldInvalid('location') ? 'p-invalid w-full' : 'w-full')} />
                        {getFormErrorMessage('location')}
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-6">
                    <div className="field">
                        <label htmlFor="state" className="text-regular font-l">State</label>
                        <Dropdown value={formik.values.state} onChange={(e) => {
                            formik.setFieldValue('state', e.target.value);
                        }} options={[{ name: 'AP', value: 'Andhra Pradesh' }, { name: 'TG', value: 'Telangana' }]}
                            optionLabel="name" optionValue="value"
                            placeholder="Select State" className={classNames(isFormFieldInvalid('state') ? 'p-invalid w-full' : 'w-full')} />
                        {getFormErrorMessage('state')}
                    </div>
                </div>

            </div>
            <div className="grid">
                <div className="col-12">
                    <h3>Working Model</h3>
                </div>
                <div className="col-12 md:col-6 lg:col-6">
                    <div className="field">
                        <div className="flex flex-wrap gap-3">
                            <div className="flex align-items-center">
                                <RadioButton
                                    inputId="subvention"
                                    name="subvention"
                                    checked={formik.values.workingModel === "subvention"}
                                    value="subvention" onChange={(e) => {
                                        console.log('####', e);
                                        formik.setFieldValue('workingModel', e.target.name);
                                    }} />
                                <label className="ml-2">Subvention</label>
                            </div>
                            {formik.values.workingModel === "subvention" && <InputText value={formik.values.subventionInterestRate} placeholder="Enter Interest Rate" onChange={(e) => {
                                formik.setFieldValue('subventionInterestRate', e.value);
                            }}
                                className={classNames(isFormFieldInvalid('subventionInterestRate') ? 'p-invalid w-full' : 'w-full')} />}
                        </div>
                    </div>
                    {console.log("???", formik.values)}
                </div>
                <div className="col-12 md:col-6 lg:col-6">
                    <div className="field">
                        <div className="flex flex-wrap gap-3">
                            <div className="flex align-items-center">
                                <RadioButton inputId="costToParent" name="costToParent"
                                    checked={formik.values.workingModel === "costToParent"}
                                    value="costToParent" onChange={(e) => {
                                        formik.setFieldValue('workingModel', e.target.name);
                                    }} />
                                <label htmlFor="costToParent" className="ml-2">Cost to Parent</label>
                            </div>
                            {formik.values.workingModel === "costToParent" && <InputText value={formik.values.costToParentInterestRate} placeholder="Enter Interest Rate" onChange={(e) => {
                                formik.setFieldValue('costToParentInterestRate', e.target.value);
                            }}
                                className={classNames(isFormFieldInvalid('costToParentInterestRate') ? 'p-invalid w-full' : 'w-full')} />}
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-12 lg:col-12">
                    <div className="field">
                        <div className="flex flex-wrap gap-3">
                            <div className="flex align-items-center">
                                <RadioButton inputId="hybrid" name="hybrid"
                                    checked={formik.values.workingModel === "hybrid"}
                                    value={formik.values.hybrid} onChange={(e) => {
                                        formik.setFieldValue('workingModel', e.target.name);
                                    }} />
                                <label htmlFor="hybrid" className="ml-2">Hybrid</label>
                            </div>
                        </div>
                    </div>
                </div>
                {formik.values.workingModel === "hybrid" && <div className="col-12 md:col-6 lg:col-6">
                    <div className="field">
                        <label htmlFor="instituteContribution" className="text-regular font-l">Institute Contribution</label>
                        <InputText value={formik.values.instituteContribution} placeholder="Enter Interest Rate" onChange={(e) => {
                            formik.setFieldValue('instituteContribution', e.target.value);
                        }}
                            className={classNames(isFormFieldInvalid('instituteContribution') ? 'p-invalid w-full' : 'w-full')} />
                    </div>
                </div>}
                {formik.values.workingModel === "hybrid" && <div className="col-12 md:col-6 lg:col-6">
                    <div className="field">
                        <label htmlFor="parentContribution" className="text-regular font-l">Parent Contribution</label>
                        <InputText value={formik.values.parentContribution} placeholder="Enter Interest Rate" onChange={(e) => {
                            formik.setFieldValue('parentContribution', e.target.value);
                        }}
                            className={classNames(isFormFieldInvalid('parentContribution') ? 'p-invalid w-full' : 'w-full')} />
                    </div>
                </div>}
            </div>

            <div className="grid m-0 pt-4 border-top-1">
                <div className="col-12">
                    <h3>Bank Details</h3>
                </div>
                <div className="col-12 md:col-6 lg:col-6">
                    <div className="field">
                        <label htmlFor="beneficiaryName" className="text-regular font-l">Beneficiary Name</label>
                        <InputText value={formik.values.beneficiaryName} placeholder="Enter beneficiary name" onChange={(e) => {
                            formik.setFieldValue('beneficiaryName', e.target.value);
                        }}
                            className={classNames(isFormFieldInvalid('beneficiaryName') ? 'p-invalid w-full' : 'w-full')} />
                        {getFormErrorMessage('beneficiaryName')}
                    </div>
                </div>

                <div className="col-12 md:col-6 lg:col-6">
                    <div className="field">
                        <label htmlFor="accountNumber" className="text-regular font-l">Account Number</label>
                        <InputText value={formik.values.accountNumber} placeholder="Enter Account number" onChange={(e) => {
                            formik.setFieldValue('accountNumber', e.target.value);
                        }}
                            className={classNames(isFormFieldInvalid('accountNumber') ? 'p-invalid w-full' : 'w-full')} />
                        {getFormErrorMessage('accountNumber')}
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-6">
                    <div className="field">
                        <label htmlFor="ifsc" className="text-regular font-l">IFSC</label>
                        <InputText value={formik.values.ifsc} placeholder="Enter IFSC" onChange={(e) => {
                            formik.setFieldValue('ifsc', e.target.value);
                        }}
                            className={classNames(isFormFieldInvalid('ifsc') ? 'p-invalid w-full' : 'w-full')} />
                        {getFormErrorMessage('ifsc')}
                    </div>
                </div>
            </div>
            <div className="grid m-0 pt-4 border-top-1">
                <div className="col-12">
                    <h3>EMI Options</h3>
                </div>
                <div className="col-12 md:col-6 lg:col-6">
                    <InputText value={formik.values.emi} placeholder="Enter EMI" onChange={(e) => {
                        formik.setFieldValue('emi', e.target.emi);
                    }}
                        className={classNames(isFormFieldInvalid('emi') ? 'p-invalid w-full' : 'w-full')} />
                    <Slider value={formik.values.emi} onChange={(e) => formik.setFieldValue('emi', e.value)} className="w-14rem" min={3} max={24} step={3} />
                </div>

            </div>
            <Button label="Save" type="submit" className="mt-4" />
            <Toast ref={toast} />
        </form>
    )
}

export default BankDetails