import React, { useEffect, useState } from "react";
import DraftGrid from "./DraftGrid";
import PendingGrid from "./PendingGrid";
import ApprovedGrid from "./ApprovedGrid";
import DeclinedGrid from "./DeclinedGrid";
import { useMediaQuery } from "react-responsive";

const StatusGrid = (props) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    });
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const { loanStatus, applicationNo } = props;
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('currentUser')));
    const applicationInfo = useState(JSON.parse(localStorage.getItem('applicationInfo')));

    useEffect(() => {
        setCurrentUser(JSON.parse(localStorage.getItem('currentUser')));
    }, []);

    return (
        <div className={isDesktopOrLaptop ? "grid ml-4 mt-8 mr-4" : 'grid m-0'}>
            {loanStatus === 'DRAFT' ? <DraftGrid /> : loanStatus === 'PENDING' ? <PendingGrid currentUser={currentUser} applicationNo={applicationNo} /> : loanStatus === 'APPROVED' ? <ApprovedGrid currentUser={currentUser} /> : <DeclinedGrid currentUser={currentUser} />}
        </div>
    )
}

export default StatusGrid;