import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useRef } from "react";
import { useState } from "react";
import { BoardFormSchema } from "../../schemas";
import api from "../../api/services";
import { useNavigate } from "react-router-dom";
import { classNames } from 'primereact/utils';
import { Button } from "primereact/button";

const AddBoard = (props) => {
    const { visible, record } = props;
    const navigate = useNavigate();
    const toast = useRef(null);
    const validationSchema = BoardFormSchema;
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [boards, setBoards] = useState(record.boards)
    const [selectedBoard, setSelectedBoard] = useState('');
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            board: '',
        },
        validationSchema,
        onSubmit: (data) => {
        },
    });
    const isFormFieldInvalid = (name) => formik.touched[name] && formik.errors[name];
    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const onClickAdd = () => {
        api.post('/institute/addInstituteBoard/' + record._id, { board: formik.values.board }).then(res => {
            if (res.status === 200 && res?.data) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Board added successfully' });
                setBoards(res.data?.boards);
                formik.setFieldValue('board', '');
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res?.data.message });
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
            setLoading(false);
        });
    }
    const onClickEdit = (board) => {
        setSelectedBoard(board._id)
        setEditMode(true);
        formik.setFieldValue('board', board.boardName);
    }

    const onClickUpdate = (board) => {
        api.put('/institute/updateBoard/' + record._id + "/" + board._id, { board: formik.values.board }).then(res => {
            if (res.status === 200 && res?.data) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Board updated successfully' });
                setEditMode(false);
                let allBoards = [...boards];
                boards.forEach(obj => {
                    if (res.data?.data?._id === obj._id) {
                        obj.boardName = res.data?.data.boardName;
                    }
                });
                setBoards(allBoards);
                formik.setFieldValue('board', '');
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res?.data.message });
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
            setLoading(false);
        });
    };

    const deleteBoard = (board) => {
        api.post('/institute/deleteInstituteBoard/' + record._id + "/" + board._id).then(res => {
            if (res.status === 200 && res?.data) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Board deleted successfully' });
                setBoards(res.data?.data?.boards);
                formik.setFieldValue('board', '');
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res?.data.message });
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
            setLoading(false);
        });
    };

    return (
        <Dialog header="Boards" visible={visible} onHide={() => props.hidePopup()} >
            <form onSubmit={formik.handleSubmit}  >
                {boards?.map((col, i) => (
                    <div key={i} className="mb-2 flex align-items-center p-2 w-full">
                        <div className="w-50">
                            {editMode && selectedBoard === col._id ? <InputText value={formik.values.board} placeholder="Enter New Board" onChange={(e) => {
                                formik.setFieldValue('board', e.target.value);
                            }}
                                className={classNames(isFormFieldInvalid('board') ? 'p-invalid' : '')} /> : <p className="m-0">{col.boardName}</p>
                            }
                        </div>
                        <div className="w-50 text-right">
                            <div className="felx justify-content-center align-items-center">
                                {!editMode && <i className="pi pi-pencil pointer ml-2" onClick={() => onClickEdit(col)}></i>}
                                {!editMode && <i className="pi pi-trash pointer ml-2" onClick={() => deleteBoard(col)}></i>}
                                {editMode && selectedBoard === col._id && <i className="pi pi-times pointer ml-2" onClick={() => { setEditMode(false); formik.setFieldValue('board', ''); }}></i>}
                                {editMode && selectedBoard === col._id && <i className={`pi pi-check pointer ml-2 ${formik.values.board === '' ? 'disabled' : ''}`} onClick={() => { onClickUpdate(col) }}></i>}
                            </div>
                        </div>
                    </div>
                ))}
                {!editMode && <div className="flex">
                    <div >
                        <InputText value={formik.values.board} placeholder="Enter New Board" onChange={(e) => {
                            formik.setFieldValue('board', e.target.value);
                        }}
                            className={classNames(isFormFieldInvalid('board') ? 'p-invalid' : '')} />
                        {getFormErrorMessage('board')}
                    </div>
                    <div className="flex justify-content-center align-items-center">
                        <i className={`pi pi-plus pointer ml-2 ${formik.values.board === '' ? 'disabled' : ''}`} onClick={() => onClickAdd()}></i>
                    </div>
                </div>}
                <Toast ref={toast} />
            </form>
        </Dialog>
    )
}

export default AddBoard;