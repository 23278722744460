import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ApplicationForm from "../pages/applicationForm/ApplicationForm";
import ApplicationFormThree from "../pages/applicationForm/ApplicationFormThree";
import KycForm from "../pages/applicationForm/KycForm";
import StudentForm from "../pages/applicationForm/StudentForm";
import ApplicationManagement from "../pages/applicationManagement/ApplicationManagement";
import CheckEmi from "../pages/checkEmi/CheckEmi";
import Courses from "../pages/courses/Courses";
import Dashboard from "../pages/dashboard/Dashboard";
import Home from "../pages/home/Home";
import NewUserHome from "../pages/home/NewUserHome";
import Institutes from "../pages/institutes/Institutes";
import Login from "../pages/login/Login";
import OtpForm from "../pages/login/OtpForm";
import PasswordLogin from "../pages/login/PasswordLogin";
import Payments from "../pages/payments/Payments";
import Registration from "../pages/registration/Registration";
import Rewards from "../pages/rewards/Rewards";
import Settings from "../pages/settings/Settings";
import Thankyou from "../pages/thankyou/Thankyou";
import Users from "../pages/users/Users";
import AddNewInstitute from "../pages/institutes/AddNewInstitute";

const Routing = () => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const isUserLogin = () => {
        return currentUser;
    }
    return (
        <Routes>
            {isUserLogin() ?
                <>
                    <Route exact path='/' element={< NewUserHome />}></Route>
                    <Route exact path='/new-user-home' element={< NewUserHome />}></Route>
                    <Route exact path='/dashboard/new-user' element={< Registration />}></Route>
                    <Route exact path='/application-form-one' element={< StudentForm />}></Route>
                    <Route exact path='/application-form-two' element={< KycForm />}></Route>
                    <Route exact path='/application-form-three' element={< ApplicationFormThree />}></Route>
                    <Route exact path='/dashboard' element={< Home />}></Route>
                    <Route exact path='/payments' element={< Payments />}></Route>
                    <Route exact path='/rewards' element={< Rewards />}></Route>
                    <Route exact path='/settings' element={< Settings />}></Route>
                    <Route exact path='/check-emi' element={< CheckEmi />}></Route>
                    <Route exact path='/dashboard/thankyou' element={< Thankyou />}></Route>
                    <Route exact path='/application-management' element={< ApplicationManagement />}></Route>
                    <Route exact path='/institutes' element={< Institutes />}></Route>
                    <Route exact path='/institute/add' element={< AddNewInstitute />}></Route>

                    <Route exact path='/courses' element={< Courses />}></Route>
                    <Route exact path='/users' element={< Users />}></Route>
                </> :
                <>
                    <Route exact path='/otp-login' element={< Login />}></Route>
                    <Route exact path='/password-login' element={< Login />}></Route>
                    <Route exact path='/otp-existing-user' element={< OtpForm />}></Route>
                </>
            }
        </Routes>
    )

}

export default Routing;