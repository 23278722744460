import React, { useEffect, useRef, useState } from "react";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useNavigate } from "react-router-dom";
import { Card } from 'primereact/card';
import RECEIPT from '../../assets/images/receipt.svg';
import BADGE_PERCENT from '../../assets/images/badge-percent.svg';
import BADGE_CHECK from '../../assets/images/badge-check.svg';
import THREE_MONTHS from '../../assets/images/3months.svg';
import SIX_MONTHS from '../../assets/images/6months.svg';
import NINE_MONTHS from '../../assets/images/9months.svg';
import { ProgressSpinner } from 'primereact/progressspinner';
import './Application.scss';
import { useFormik } from "formik";
import { Toast } from 'primereact/toast';
import api from "../../api/services";
import { StudentFormSchema } from "../../schemas";
import FormBanner from "./FormBanner";
import { useMediaQuery } from "react-responsive";


const StudentForm = () => {
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    });
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const toast = useRef(null);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const initialValues = {
        instituteId: '',
        state: '',
        courseId: '',
        acdemicYear: '',
        firstName: '',
        lastName: '',
        instituteStudentId: '',
        studenttype: '',
        class: '',
        courseFee: '',
    };

    const [emiPlan, setEmiPlan] = useState({
        paybleEmi: 1,
        planOneTenure: 3,
        planTwoTenure: 6,
        planThreeTenure: 9,
        intrest: 0,
        planOneAmount: null,
        planTwoAmount: null,
        planThreeAmount: null,
    });
    const [selectedCourseFee, setSelectedCourseFee] = useState(null);
    const [instituteList, setInstitutes] = useState([]);
    const [studentDetails, setStudentDetails] = useState({});
    const [tenure, setTenure] = useState(null);
    const [courseList, setCourseList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [showSpinner, setSpinner] = useState(false);
    const [arePlansLoaded, setPlansLoader] = useState(false);
    const [loading, setLoading] = useState(true);
    const [btnAction, setBtnAction] = useState(null);
    const [mainCourseList, setMainCourseList] = useState([]);


    const { values, errors, touched, handleBlur, handleChange, handleSubmit, getFieldProps } = useFormik({
        initialValues: studentDetails || initialValues,
        validationSchema: StudentFormSchema,
        enableReinitialize: true,
        onSubmit: () => {
            if (btnAction === "SUBMIT") {
                if (!tenure) {
                    toast.current.show({ severity: 'error', summary: '', detail: 'Please select EMI Plan' });
                } else {
                    if (currentUser?.studentId) {
                        navigate('/application-form-two', { state: studentDetails });
                    } else
                        addStudent();
                }
                setBtnAction(null);
            }
        },

    });

    useEffect(() => {
        if (!currentUser?.studentId && values.courseFee !== '' && values.courseFee) {
            if (values.courseId === '' || !values.courseId) {
                values.courseFee = '';
                toast.current.show({ severity: 'error', detail: 'Please select course' });
                return;
            }
            if (values.courseFee == 0) {
                toast.current.show({ severity: 'error', detail: 'Amount should not be Zero' });
                values.courseFee = '';
                return
            }
            if (selectedCourseFee && selectedCourseFee !== null && values.courseFee > parseFloat(selectedCourseFee.replace(/,/g, ''))) {
                toast.current.show({ severity: 'error', detail: 'Amount should not be exceed course fee:' + ' ' + selectedCourseFee });
                values.courseFee = '';
                errors.courseFee = 'Amount should not be exceed course fee:' + ' ' + selectedCourseFee;
                setEmiPlan({
                    ...emiPlan,
                    planOneAmount: null,
                    planTwoAmount: null,
                    planThreeAmount: null
                });
                setSpinner(false);
                setPlansLoader(false);
                return
            }
            errors.courseFee = undefined;

            setPlansLoader(false);
            setSpinner(true);
            const fee = Number(values.courseFee);
            const planOne = ((((fee * emiPlan.intrest) / 100) * emiPlan.planOneTenure) + fee) / emiPlan.planOneTenure;
            const planTwo = ((((fee * emiPlan.intrest) / 100) * emiPlan.planTwoTenure) + fee) / emiPlan.planTwoTenure;
            const planThree = ((((fee * emiPlan.intrest) / 100) * emiPlan.planThreeTenure) + fee) / emiPlan.planThreeTenure;
            setEmiPlan({
                ...emiPlan,
                planOneAmount: Math.round(planOne),
                planTwoAmount: Math.round(planTwo),
                planThreeAmount: Math.round(planThree)
            });

            setTimeout(() => {
                setSpinner(false);
                setPlansLoader(true);
            }, [1000]);
        } else {
            setSpinner(false);
            setPlansLoader(true);
        }
    }, [values.courseFee]);

    useEffect(() => {
        if (values.instituteId === '') {
            return
        }
        let instituteName;
        instituteList.forEach(element => {
            if (element._id === values.instituteId) {
                instituteName = element.instituteName
                setEmiPlan({
                    ...emiPlan,
                    intrest: element.interestRate
                })
            }
        });
        onChangeInstitute(instituteName)
    }, [values.instituteId]);

    useEffect(() => {
        if (currentUser?.studentId) {
            return
        }
        onChangeCourse(values.courseId);
    }, [values.courseId]);

    useEffect(() => {
        if (!currentUser?.studentId && values.class && values.class !== '') {
            values.courseFee = '';
            setTenure('');
            setEmiPlan({
                ...emiPlan,
                planOneAmount: null,
                planTwoAmount: null,
                planThreeAmount: null
            });
            const list = mainCourseList.filter(obj => {
                return obj.class === values.class && obj.courseName === values.courseId;
            })
            setSelectedCourseFee(list.length > 0 ? list[0].coursefee : null);
        }
    }, [values.class]);


    useEffect(() => {
        getDropdownOptions();
    }, []);


    const getDropdownOptions = () => {
        api.get('/institute').then(response => {
            if (response?.status === 200) {
                let list = [...response.data];
                list.forEach(element => {
                    element.state = element.location + ' - ' + element.state;
                });
                setInstitutes(list);
                if (currentUser?.studentId) {
                    getStudentDetails(response.data);
                } else {
                    setLoading(false);
                }
            } else {
                toast.current.show({ severity: 'error', detail: response.statusText });
                setLoading(false);
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', detail: err.message });
            setLoading(false);
        })
    };


    const addStudent = () => {
        let body = { ...values };
        body['phone'] = currentUser?.phone;
        body['tenure'] = tenure;
        body.courseId = mainCourseList.filter(obj => { return obj.class === values.class })[0]._id;
        api.post('/addStudent', body).then(res => {
            if (res?.data?.errors) {
                toast.current.show({ severity: 'error', summary: res.data.name, detail: res.data.message });
            } else {
                let data = res.data;
                data.studentId = data._id;
                setStudentDetails(res.data);
                setTenure(res.data.tenure);
                data.phone = currentUser?.phone;
                data.userType = currentUser?.userType;
                data = { ...currentUser, ...data }
                localStorage.setItem('currentUser', JSON.stringify(data));
                toast.current.show({ severity: 'success', detail: 'Student added successfuly' });
                setTimeout(() => {
                    navigate('/application-form-two', { state: res.data });
                }, [1000]);
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', detail: err.message });
        })
    };

    const getStudentDetails = (institutes) => {
        api.get('/getStudentInfo/' + currentUser?.phone).then((response) => {
            if (response.status === 200 && response.data?.result) {
                let data = response.data?.result;
                institutes.forEach(element => {
                    if (element._id === data.instituteId[0]) {
                        data.state = element.state;
                        data.instituteId = element.instituteName;
                        data.acdemicYear = element.acdemicYear;
                        api.get('/courses/' + element.instituteName).then(response => {
                            setLoading(false);
                            if (response?.status === 200) {
                                response.data.result.forEach(element => {
                                    if (element._id === data.courseId[0]) {
                                        data.courseId = element.courseName;
                                    }
                                });
                                setStudentDetails(data);

                            } else {
                                toast.current.show({ severity: 'error', detail: response.message });
                                setLoading(false);
                            }
                        }).catch(err => {
                            if (err.response.status === 401) {
                                localStorage.clear();
                                navigate('/otp-login');
                                return;
                            }
                            toast.current.show({ severity: 'error', detail: err.message });
                            setLoading(false);
                        })
                    }
                });
                setFormData(data)
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.status });
            }
            setLoading(false);

        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
            setLoading(false);
        });
    }
    const setFormData = (data) => {
        const fee = Number(data.courseFee);
        const planOne = ((((fee * emiPlan.intrest) / 100) * emiPlan.planOneTenure) + fee) / emiPlan.planOneTenure;
        const planTwo = ((((fee * emiPlan.intrest) / 100) * emiPlan.planTwoTenure) + fee) / emiPlan.planTwoTenure;
        const planThree = ((((fee * emiPlan.intrest) / 100) * emiPlan.planThreeTenure) + fee) / emiPlan.planThreeTenure;
        setEmiPlan({
            ...emiPlan,
            planOneAmount: Math.round(planOne),
            planTwoAmount: Math.round(planTwo),
            planThreeAmount: Math.round(planThree)
        });
        setTenure(data.tenure)
    }


    const getMeStatesList = () => {
        if (values.instituteId)
            return instituteList.filter(obj => {
                return obj._id === values.instituteId
            }).filter((v, i, a) => a.findIndex(v2 => (v2.state === v.state)) === i)
    };

    const getMeAccYearList = () => {
        if (values.instituteId)
            return instituteList.filter(obj => {
                return obj._id === values.instituteId
            }).filter((v, i, a) => a.findIndex(v2 => (v2.acdemicYear === v.acdemicYear)) === i)
    }

    const onChangeInstitute = (e) => {
        if (e && e !== '') {
            setLoading(true);
            values.courseId = '';
            values.class = '';
            values.courseFee = '';
            setEmiPlan({
                ...emiPlan,
                planOneAmount: null,
                planTwoAmount: null,
                planThreeAmount: null
            });
            getMeCourseList(e);
        }
    }
    const onChangeCourse = (e) => {
        if (e && e !== '') {
            values.class = '';
            values.courseFee = '';
            setEmiPlan({
                ...emiPlan,
                planOneAmount: null,
                planTwoAmount: null,
                planThreeAmount: null
            });
            getMeClassList(e);

        }
    }

    const getMeClassList = (e) => {
        const list = mainCourseList.length === 0 ? [] : mainCourseList.filter(obj => { return obj.courseName === e });
        setClassList(list);
    }

    const getMeCourseList = (e) => {
        api.get('/courses/' + e).then(response => {
            setLoading(false);
            if (response?.status === 200) {
                const arr = [];
                setMainCourseList(response.data.result);
                response.data.result.reduce((acc, obj) => {
                    if (acc.indexOf(obj.courseName) === -1) {
                        acc.push(obj.courseName);
                        arr.push(obj)
                    }
                    return acc;
                }, [])
                setCourseList(arr);
            } else {
                toast.current.show({ severity: 'error', detail: response.message });
                setLoading(false);
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', detail: err.message });
            setLoading(false);
        })
    }

    return (
        <div className="grid">
            <FormBanner />
            <div className={isDesktopOrLaptop ? "form-layout-desktop" : "form-layout-mobile"}>
                {loading ? <ProgressSpinner className="absolute top-50 left-50" /> :
                    <form onSubmit={handleSubmit} className="login-form" >
                        <div className="col-12 p-5 border-gray-400 border-1 border-round mb-5">
                            <p className="text-xl text-primary">Enter Institution Details</p>
                            <div className="grid">
                                <div className="col-12 md:col-6 lg:col-6">
                                    <div className="field">
                                        <label htmlFor="instituteId" className="text-regular font-l">Institute name</label>
                                        {currentUser?.studentId ?
                                            <InputText disabled value={values.instituteId} className=" w-full" />
                                            : <Dropdown name="instituteId" disabled={currentUser?.studentId} value={values.instituteId} filter options={instituteList} optionLabel="instituteName" optionValue="_id" placeholder="Select Institute Name" className="w-full" onBlur={handleBlur}  {...getFieldProps('instituteId')} />}
                                        {touched.instituteId && errors.instituteId ? (
                                            <div className="p-invalid">{errors.instituteId}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 lg:col-6">
                                    <div className="field">
                                        <label htmlFor="state" className="text-regular font-l">Location</label>
                                        {currentUser?.studentId ?
                                            <InputText disabled value={values.state} className=" w-full" />
                                            : <Dropdown name="state" disabled={currentUser?.studentId} value={values.state} options={getMeStatesList()} optionLabel="state" optionValue="state" placeholder="Select State" className="w-full"  {...getFieldProps('state')} onBlur={handleBlur} />
                                        }
                                        {touched.state && errors.state ? (
                                            <div className="p-invalid">{errors.state}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 lg:col-6">
                                    <div className="field">
                                        <label htmlFor="courseId" className="text-regular font-l">Course/Board</label>
                                        {currentUser?.studentId ?
                                            <InputText disabled value={values.courseId} className=" w-full" />
                                            : <Dropdown name="courseId" disabled={currentUser?.studentId} value={values.courseId} options={courseList} optionLabel="courseName" optionValue="courseName" placeholder="Select Course" className="w-full" {...getFieldProps('courseId')} onBlur={handleBlur} />
                                        }
                                        {touched.courseId && errors.courseId ? (
                                            <div className="p-invalid">{errors.courseId}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 lg:col-6">
                                    <div className="field">
                                        <label htmlFor="acdemicYear" className="text-regular font-l">Academic year/term</label>
                                        {currentUser?.studentId ?
                                            <InputText disabled value={values.acdemicYear} className=" w-full" />
                                            : <Dropdown name="acdemicYear" disabled={currentUser?.studentId} value={values.acdemicYear} options={getMeAccYearList()} optionLabel="acdemicYear" optionValue="acdemicYear" placeholder="Select academic year/term" className="w-full" {...getFieldProps('acdemicYear')} onBlur={handleBlur} />}
                                        {touched.acdemicYear && errors.acdemicYear ? (
                                            <div className="p-invalid">{errors.acdemicYear}</div>
                                        ) : null}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-12 p-5 border-gray-400 border-1 border-round mb-5">
                            <p className="text-xl text-primary"> Enter Student Details</p>
                            <div className="grid">
                                <div className="col-12 md:col-6 lg:col-6">
                                    <div className="field">
                                        <label htmlFor="firstName" className="text-regular font-l">First Name</label>
                                        <InputText id="firstName" disabled={currentUser?.studentId} value={values.firstName} placeholder="Enter First Name" className=" w-full" {...getFieldProps('firstName')} onBlur={handleBlur} />
                                        {touched.firstName && errors.firstName ? (
                                            <div className="p-invalid">{errors.firstName}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 lg:col-6">
                                    <div className="field">
                                        <label htmlFor="lastName" className="text-regular font-l">Last Name</label>
                                        <InputText id="lastName" disabled={currentUser?.studentId} value={values.lastName} name="lastName" placeholder="Enter Last Name" className=" w-full" {...getFieldProps('lastName')} onBlur={handleBlur} />
                                        {touched.lastName && errors.lastName ? (
                                            <div className="p-invalid">{errors.lastName}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 lg:col-6">
                                    <div className="field">
                                        <label htmlFor="instituteStudentId" className="text-regular font-l">Student ID</label>
                                        <InputText id="instituteStudentId" disabled={currentUser?.studentId} value={values.instituteStudentId} placeholder="Enter Student ID" className=" w-full" {...getFieldProps('instituteStudentId')} onBlur={handleBlur} />
                                        {touched.instituteStudentId && errors.instituteStudentId ? (
                                            <div className="p-invalid">{errors.instituteStudentId}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 lg:col-6">
                                    <div className="field">
                                        <label htmlFor="studenttype" className="text-regular font-l">Student Type</label>
                                        <Dropdown value={values.studenttype} disabled={currentUser?.studentId} options={[{ id: 1, studenttype: 'New' }, { id: 2, studenttype: 'Existing' }]} placeholder="Select Student Type" optionLabel="studenttype" optionValue="studenttype" className=" w-full" {...getFieldProps('studenttype')} onBlur={handleBlur} />
                                        {touched.studenttype && errors.studenttype ? (
                                            <div className="p-invalid">{errors.studenttype}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 lg:col-6">
                                    <div className="field">
                                        <label htmlFor="class" className="text-regular font-l">Class/Section</label>

                                        {currentUser?.studentId ?
                                            <InputText disabled value={values.class} className=" w-full" />
                                            : <Dropdown name="class" disabled={currentUser?.studentId} value={values.class} options={classList} optionLabel="class" optionValue="class" placeholder="Select class" className="w-full" {...getFieldProps('class')} onBlur={handleBlur} />}

                                        {touched.class && errors.class ? (
                                            <div className="p-invalid">{errors.class}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 lg:col-6">
                                    <div className="field">
                                        <label htmlFor="courseFee" className="text-regular font-l">Amount Required</label>
                                        <InputText id="courseFee" disabled={currentUser?.studentId} value={values.courseFee} placeholder="Enter Amount Required" className=" w-full" onBlur={handleBlur} {...getFieldProps('courseFee')} />
                                        {touched.courseFee && errors.courseFee ? (
                                            <div className="p-invalid">{errors.courseFee}</div>
                                        ) : null}
                                    </div>
                                </div>

                            </div>
                        </div>

                        {arePlansLoaded && (emiPlan.planTwoAmount !== null && emiPlan.planOneAmount !== null && emiPlan.planThreeAmount !== null) && < div className={isDesktopOrLaptop ? "col-12 p-4 mb-5" : "col-12 mb-5"}>
                            <p className="text-xl text-primary">Select your Payment Plan</p>
                            <div className={isDesktopOrLaptop ? "grid pl-6 pr-6 " : "grid"} >
                                <div className="col-12 md:col-4 lg:col-4 ">
                                    <Card className="form-payment-card relative">
                                        <img src={THREE_MONTHS} alt="3 months" className="absolute top-0 right-0 border-round-right-sm" />
                                        <div className="flex">
                                            <p className="title">
                                                3 Months Plan
                                            </p>
                                        </div>
                                        <div className="flex mt-3">
                                            <p className="plan-amount">
                                                RS. {emiPlan.planOneAmount.toLocaleString('en-IN')}
                                            </p>
                                        </div>
                                        <div className="flex align-items-center flex-wrap card-container mt-3">
                                            <p className="no-of-emi flex  align-items-center justify-content-center flex-wrap"><img src={RECEIPT} alt="Receipt" className="mr-2" /> No. of EMI: {emiPlan.planOneTenure}</p>
                                        </div>
                                        <div className="flex align-items-center flex-wrap card-container mt-3">
                                            <p className="no-of-emi flex  align-items-center justify-content-center flex-wrap"><img src={BADGE_PERCENT} alt="Badge Percent" className="mr-2" /> Interst: {emiPlan.intrest}%</p>
                                        </div>
                                        <div className="flex align-items-center flex-wrap card-container mt-3">
                                            <p className="no-of-emi flex  align-items-center justify-content-center flex-wrap"><img src={BADGE_CHECK} alt="Badge Check" className="mr-2" /> {emiPlan.paybleEmi} EMI Payable</p>
                                        </div>
                                        <Button label="Select Plan" disabled={currentUser?.studentId} className={`w-full mt-3 ${tenure === '3' ? 'p-button' : 'p-button-outlined'}`} onClick={() => { setTenure('3') }} />
                                    </Card>
                                </div>
                                <div className="col-12 md:col-4 lg:col-4 ">
                                    <Card className="form-payment-card relative">
                                        <img src={SIX_MONTHS} alt="6 months" className="absolute top-0 right-0 border-round-right-sm" />
                                        <div className="flex">
                                            <p className="title">
                                                6 Months Plan
                                            </p>
                                        </div>
                                        <div className="flex mt-3">
                                            <p className="plan-amount">
                                                RS. {emiPlan.planTwoAmount.toLocaleString('en-IN')}
                                            </p>
                                        </div>
                                        <div className="flex align-items-center flex-wrap card-container mt-3">
                                            <p className="no-of-emi flex  align-items-center justify-content-center flex-wrap"><img src={RECEIPT} alt="Receipt" className="mr-2" /> No. of EMI: {emiPlan.planTwoTenure}</p>
                                        </div>
                                        <div className="flex align-items-center flex-wrap card-container mt-3">
                                            <p className="no-of-emi flex  align-items-center justify-content-center flex-wrap"><img src={BADGE_PERCENT} alt="Badge Percent" className="mr-2" /> Interst: {emiPlan.intrest}%</p>
                                        </div>
                                        <div className="flex align-items-center flex-wrap card-container mt-3">
                                            <p className="no-of-emi flex  align-items-center justify-content-center flex-wrap"><img src={BADGE_CHECK} alt="Badge Check" className="mr-2" /> {emiPlan.paybleEmi} EMI Payable</p>
                                        </div>
                                        <Button label="Select Plan" disabled={currentUser?.studentId} className={`w-full mt-3 ${tenure === "6" ? 'p-button' : 'p-button-outlined'}`} onClick={() => { setTenure("6") }} />
                                    </Card>
                                </div>
                                <div className="col-12 md:col-4 lg:col-4 ">
                                    <Card className="form-payment-card relative">
                                        <img src={NINE_MONTHS} alt="9 months" className="absolute top-0 right-0 border-round-right-sm" />
                                        <div className="flex">
                                            <p className="title">
                                                9 Months Plan
                                            </p>
                                        </div>
                                        <div className="flex mt-3">
                                            <p className="plan-amount">
                                                RS. {emiPlan.planThreeAmount.toLocaleString('en-IN')}
                                            </p>
                                        </div>
                                        <div className="flex align-items-center flex-wrap card-container mt-3">
                                            <p className="no-of-emi flex  align-items-center justify-content-center flex-wrap"><img src={RECEIPT} alt="Receipt" className="mr-2" /> No. of EMI: {emiPlan.planThreeTenure}</p>
                                        </div>
                                        <div className="flex align-items-center flex-wrap card-container mt-3">
                                            <p className="no-of-emi flex  align-items-center justify-content-center flex-wrap"><img src={BADGE_PERCENT} alt="Badge Percent" className="mr-2" /> Interst: {emiPlan.intrest}%</p>
                                        </div>
                                        <div className="flex align-items-center flex-wrap card-container mt-3">
                                            <p className="no-of-emi flex  align-items-center justify-content-center flex-wrap"><img src={BADGE_CHECK} alt="Badge Check" className="mr-2" /> {emiPlan.paybleEmi} EMI Payable</p>
                                        </div>
                                        <Button label="Select Plan" disabled={currentUser?.studentId} className={`w-full mt-3 ${tenure === "9" ? 'p-button' : 'p-button-outlined'}`} onClick={() => { setTenure("9") }} />
                                    </Card>
                                </div>
                            </div>

                        </div>
                        }
                        {
                            showSpinner && < div className="flex justify-content-center">
                                <ProgressSpinner />
                            </div>
                        }

                        <div className={isDesktopOrLaptop ? "col-12 p-4 mb-5" : "col-12 mb-5"}>
                            <div className="flex justify-content-center flex-wrap card-container yellow-container">
                                <div className="flex align-items-center justify-content-center">
                                    {currentUser?.studentId ? <Button type="submit" label='Next' className="w-full login-button" onClick={() => {
                                        navigate('/application-form-two', { state: studentDetails });
                                    }} /> :
                                        <Button label="Proceed to Application" className="w-full login-button" onClick={() => {
                                            setBtnAction('SUBMIT')
                                        }} />}

                                </div>
                            </div>
                        </div>
                    </form >}
                <Toast ref={toast} />
            </div >
        </div >
    )
}

export default StudentForm;