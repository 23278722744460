import React from "react";
import NO_USER from '../../assets/images/no-user.png'
import CIRCLE_FILLED from '../../assets/images/circle-filled.svg';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { Card } from 'primereact/card';
import './Settings.scss'
import { useEffect } from "react";
import api from "../../api/services";
import { useState } from "react";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

const Settings = () => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const navigate = useNavigate();
    const [parentInfo, setParentInfo] = useState(null);
    const toast = useRef(null);

    useEffect(() => {
        getParentDetails();
    }, [])

    const getParentDetails = () => {
        api.get('/getParentInfo/' + currentUser.phone).then(res => {
            if (res.status === 200 && res.data?.result.length > 0) {
                res.data.result[0]['dob'] = new Date(res.data?.result[0]?.dob);
                setParentInfo(res.data.result[0]);
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
        })
    };
    return (
        <div className="grid">
            <div className="col-12 md:col-6 lg:col-6">
                <p>Name & Profile Picture</p>
                <Card className="profile-card ">
                    <div className="flex justify-content-between flex-wrap">
                        <div className="flex align-items-center justify-content-center m-2">
                            <div className='align-items-center flex'>
                                <img src={NO_USER} alt="profile" className="mr-2" />
                                {parentInfo?.fullName}
                            </div >
                        </div>
                    </div>
                </Card>
            </div>
            <div className="col-12 md:col-6 lg:col-6">
                <p>Email Preferences</p>
                <Card className="email-card ">
                    <div className="flex justify-content-between flex-wrap  align-content-center">
                        <div className="flex align-items-center justify-content-center m-2">
                            <div className=''>
                                <p className="font-base font-l m-0">Primary email address</p>
                                <p className="font-base m-0">{parentInfo?.email}</p>
                            </div >
                        </div>
                    </div>
                </Card>
            </div>
            <div className="col-12 ">
                <p>Other Contact Details</p>
                <Card className="profile-card ">
                    <div className="flex justify-content-between flex-wrap">
                        <div className="flex align-items-center justify-content-center m-2">
                            <div className=''>
                                <p className="font-base font-l m-0">Phone Number</p>
                                <p className="font-base m-0">{parentInfo?.phone}</p>
                            </div >
                        </div>
                    </div>
                    <div className="flex justify-content-between flex-wrap">
                        <div className="flex align-items-center justify-content-center m-2">
                            <div className=''>
                                <p className="font-base font-l m-0">Timezone</p>
                                <p className="font-base m-0">{Intl.DateTimeFormat().resolvedOptions().timeZone} {new Date().toTimeString().slice(9)}</p>
                            </div >
                        </div>
                    </div>
                </Card>
            </div>
            <Toast ref={toast} />
        </div >
    )
};

export default Settings;