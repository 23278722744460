import React from "react";
import './Login.scss';
import LOGIN_PAGE_IMAGE from '../../assets/images/Login-page-img.svg';
import PasswordLogin from "./PasswordLogin";
import OtpLogin from "./OtpLogin";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';



const Login = () => {
    const location = useLocation();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    });
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    return (
        <>
            {isMobile && <div className="grid">
                <div className="col-12 md:col-12 lg:col-6">
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-12 text-center">
                            <img src={LOGIN_PAGE_IMAGE} alt="login" className="w-7" />
                        </div>
                        <div className="col-12 md:col-12 lg:col-12">
                            {location.pathname === '/otp-login' && <OtpLogin />}
                            {location.pathname === '/password-login' && <PasswordLogin />}
                        </div>
                    </div>
                </div>
            </div>}
            {isDesktopOrLaptop && <div className="grid">
                <div className="col-12 md:col-12 lg:col-6">
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-12">
                            {location.pathname === '/otp-login' && <OtpLogin />}
                            {location.pathname === '/password-login' && <PasswordLogin />}
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-6 text-center pt-6">
                    <img src={LOGIN_PAGE_IMAGE} alt="login" className="w-9 " />
                </div>
            </div>}
        </>
    )
}


export default Login;