import React, { useState, useRef } from "react";
import { Dialog } from 'primereact/dialog';
import { useFormik } from "formik";
import { InstititeFormSchema } from "../../schemas";
import { InputText } from "primereact/inputtext";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import { classNames } from 'primereact/utils';
import api from "../../api/services";
import { useNavigate } from "react-router-dom";
import { Toast } from 'primereact/toast';
import * as Yup from 'yup';
import { useEffect } from "react";

const AddInstitute = (props) => {
    const navigate = useNavigate();
    const toast = useRef(null);
    const { visible, record, action } = props;
    const [loading, setLoading] = useState(false);
    const validationSchema = InstititeFormSchema;
    useEffect(() => {
        if (record?.acdemicYear) {
            record['acdemicYear'] = [new Date(record.acdemicYear[0].split('-')[0]), new Date(record.acdemicYear[0].split('-')[1])]
        }
    }, [record])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: record !== null ? record : {
            instituteName: '',
            acdemicYear: '',
            status: '',
            interestRate: '',
            location: '',
            state: '',
        },
        validationSchema,
        onSubmit: (data) => {
            if (action === 'Add') {
                saveInstitute(data);
            } else {
                updateInstitute(data);
            }
        },
    });

    const saveInstitute = (data) => {
        const body = {
            instituteName: data.instituteName,
            acdemicYear: new Date(data.acdemicYear[0]).getFullYear() + "-" + new Date(data.acdemicYear[1]).getFullYear(),
            status: data.status,
            interestRate: data.interestRate,
            location: data.location,
            state: data.state,
            status: 'Active'
        };
        api.post('/institute/addInstitute/', body).then(res => {
            if (res.status === 200 && res.data?.result !== null) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Institute added successfully' });
                setTimeout(() => {
                    props.hidePopup();
                    props.getMeInstituteList();
                }, 1000);
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res?.data.message });
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
            setLoading(false);
        });
    };
    const updateInstitute = (data) => {
        const body = {
            instituteName: data.instituteName,
            acdemicYear: new Date(data.acdemicYear[0]).getFullYear() + "-" + new Date(data.acdemicYear[1]).getFullYear(),
            status: data.status,
            interestRate: data.interestRate,
            location: data.location,
            state: data.state,
            status: 'Active'
        };
        api.put('/institute/updateInstitute/' + record._id, body).then(res => {
            if (res.status === 200 && res.data?.result !== null) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Institute updated successfully' });
                setTimeout(() => {
                    props.hidePopup();
                }, 1000);
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res?.data.message });
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
            setLoading(false);
        });
    };
    const isFormFieldInvalid = (name) => formik.touched[name] && formik.errors[name];
    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const getMeHeader = () => {
        return action + " " + "Institute";
    }

    return (
        <Dialog header={getMeHeader()} visible={visible} style={{ width: '50vw' }} onHide={() => props.hidePopup()} >
            <form onSubmit={formik.handleSubmit}  >
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6">
                        <div className="field">
                            <label htmlFor="instituteName" className="text-regular font-l">Institute name</label>
                            <InputText value={formik.values.instituteName} placeholder="Enter Institute name" onChange={(e) => {
                                formik.setFieldValue('instituteName', e.target.value);
                            }}
                                className={classNames(isFormFieldInvalid('instituteName') ? 'p-invalid w-full' : 'w-full')} />
                            {getFormErrorMessage('instituteName')}
                        </div>
                    </div>

                    <div className="col-12 md:col-6 lg:col-6">
                        <div className="field">
                            <label htmlFor="calendar-12h" className="block mb-2">
                                Academic Year
                            </label>
                            <Calendar showIcon view="year" dateFormat="yy" value={formik.values.acdemicYear} onChange={(e) => {
                                formik.setFieldValue('acdemicYear', e.target.value);
                            }} selectionMode="range" readOnlyInput placeholder="Select Academic Year" className={classNames(isFormFieldInvalid('acdemicYear') ? 'p-invalid w-full' : 'w-full')} />
                            {getFormErrorMessage('acdemicYear')}
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <div className="field">
                            <label htmlFor="calendar-12h" className="block mb-2">
                                Status
                            </label>
                            <Dropdown value={formik.values.status} onChange={(e) => {
                                formik.setFieldValue('status', e.target.value);
                            }} options={[{ name: 'Active', value: 'Active' }, { name: 'Inactive', value: 'Inactive' }]}
                                optionLabel="name" optionValue="value"
                                placeholder="Select Status" className={classNames(isFormFieldInvalid('status') ? 'p-invalid w-full' : 'w-full')} />
                            {getFormErrorMessage('status')}
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <div className="field">
                            <label htmlFor="interestRate" className="text-regular font-l">Intrest Rate</label>
                            <InputText value={formik.values.interestRate} onChange={(e) => {
                                formik.setFieldValue('interestRate', e.target.value);
                            }} placeholder="Enter Intrest Rate" className={classNames(isFormFieldInvalid('interestRate') ? 'p-invalid w-full' : 'w-full')} />
                            {getFormErrorMessage('interestRate')}
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <div className="field">
                            <label htmlFor="location" className="text-regular font-l">Location</label>
                            <InputText value={formik.values.location} onChange={(e) => {
                                formik.setFieldValue('location', e.target.value);
                            }} placeholder="Enter Location" className={classNames(isFormFieldInvalid('location') ? 'p-invalid w-full' : 'w-full')} />
                            {getFormErrorMessage('location')}
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <div className="field">
                            <label htmlFor="state" className="text-regular font-l">State</label>
                            <Dropdown value={formik.values.state} onChange={(e) => {
                                formik.setFieldValue('state', e.target.value);
                            }} options={[{ name: 'AP', value: 'Andhra Pradesh' }, { name: 'TG', value: 'Telangana' }]}
                                optionLabel="name" optionValue="value"
                                placeholder="Select State" className={classNames(isFormFieldInvalid('state') ? 'p-invalid w-full' : 'w-full')} />
                            {getFormErrorMessage('state')}
                        </div>
                    </div>
                </div>
                <Button label="Save" type="submit" />
                <Toast ref={toast} />
            </form>
        </Dialog >
    )
}

export default AddInstitute;