import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from 'primereact/button';
import PerticularsTable from "./PerticularsTable";
import EmiSchedule from "./EmiSchedule";
import LoanAgreement from "./LoanAgreement";
import { useEffect } from "react";
import api from "../../api/services";
import { Toast } from 'primereact/toast';
import { useRef } from "react";



const DigitalAgreement = (props) => {
    const navigate = useNavigate();
    const toast = useRef(null);
    const applicationInfo = JSON.parse(localStorage.getItem('applicationInfo'));
    const [form, setForm] = useState({
        date: new Date().toString(),
        name: 'Dilip',
        address: 'Hyderabad',
        mobile: '7995522252',
        email: 'dilip.chandra91@gmail.com',
        applicationNumber: '123456',
        institute: 'DAV Public School',
        nbfcPartnerName: 'NBFC Partner Name'
    });
    const [applicationDetails, setApplicationDetails] = useState(null);
    useEffect(() => {
        getApplicatonDetails();
    }, [])
    const getApplicatonDetails = () => {
        api.get('/applications/getApplicationByAppNumber/' + applicationInfo.applicationNumber).then(res => {
            if (res.status === 200 && res?.data?.result) {
                setApplicationDetails(res.data.result[0])
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
        })
    };

    const onClickAgree = () => {
        props.updateUi('payment');
    };

    return (
        <>
            <div className="col-12 p-5 border-gray-400 border-1 border-round mb-5">
                <div class="flex justify-content-center flex-wrap ">
                    <div class="flex align-items-center justify-content-center">
                        [ON THE LETTER HEAD OF THE LENDER]
                    </div>


                </div>
                <div class="flex justify-content-center flex-wrap mt-5 mb-5">
                    <div class="flex align-items-center justify-content-center ">
                        SANCTION LETTER
                    </div>
                </div>

                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-12">
                        <b>{('0' + new Date().getDate()).slice(-2) + '-' + new Date().toLocaleString('default', { month: 'short' }).toUpperCase() + '-' + new Date().getFullYear()}
                        </b>
                        <p>To,</p>
                        <p>{applicationDetails?.ParentFullName}</p>

                        <p>{applicationDetails?.adress}</p>

                        <p>{applicationDetails?.parentPhone}</p>
                        <p>{applicationDetails?.parentEmail}</p>

                        <p>Dear Sir/Madam,</p>

                        <p>Sub: Education Loan Sanction Letter.
                        </p>
                        <p>Ref: Application No.&nbsp;<span className="font-semi">{applicationDetails?.applicationNumber}
                        </span>&nbsp;dated&nbsp;<span className="font-semi">
                                {('0' + new Date().getDate()).slice(-2) + '-' + new Date().toLocaleString('default', { month: 'short' }).toUpperCase() + '-' + new Date().getFullYear()}
                            </span>.
                        </p>
                        <p>
                            This is with reference to your above cited application and request for education loan&nbsp;<span className="font-semi">
                                {applicationDetails?.applicationNumber}
                            </span>&nbsp;at&nbsp;<span className="font-semi">
                                {applicationDetails?.institutename}
                            </span>. Based on the details provided by you vide your said application, we are pleased to sanction the Loan as per terms and conditions mentioned below:-
                        </p>
                        <p>This sanction is subject to the acceptance of the terms and conditions mentioned herein and execution of loan agreement and submission of other documents as required by&nbsp;<span className="font-semi">{form.nbfcPartnerName}</span>&nbsp;from time to time.</p>
                    </div>
                </div>
                <PerticularsTable applicationDetails={applicationDetails} />
                <EmiSchedule applicationDetails={applicationDetails} />
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-12">
                        <p className="font-bold">
                            Additional Specific Instructions for availing the Loan:
                        </p>
                        <ol>
                            <li>Upon the Sanction Letter, the Lender shall verify PAN details of the Borrower and in this regard the Borrower shall receive an otp on the registered mobile number of the Borrower and once the same is received by the Borrower, he/she shall share the same to the Lender.
                            </li>
                            <li>Loan shall be disbursed directly into the account of the institute/school of the Borrower.</li>
                            <li>The Borrower has to sign a separate Loan Agreement and the Sanction Letter shall form an integral part of the Loan agreement.
                            </li>
                        </ol>
                    </div>
                </div>
                <LoanAgreement />
            </div>
            <div className="col-12 p-4">
                <div className="flex justify-content-between flex-wrap card-container purple-container">
                    <Button label="Back" className="p-button-outlined" onClick={() => { navigate('/application-form-two') }} />
                    <Button label="I Agree" onClick={() => onClickAgree()} />
                </div>
            </div>
            <Toast ref={toast} />
        </>
    )
}

export default DigitalAgreement;