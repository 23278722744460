import React, { useState } from "react";
import './Institutes.scss';
import { Button } from "primereact/button";

const BranchesList = (props) => {
    const { goToBranchTab } = props;
    const list = ['branch1', 'branch2', 'branch3'];

    return (
        <div className="mt-2 p-4 md:border-solid border-primary-500 border-round ">
            <div className="grid">
                <div className="col-12 text-right">
                    <Button label="Add" onClick={() => goToBranchTab()} />
                </div>
            </div>
            <div className="flex w-100">
                {list?.map((item, index) => <div key={index} className="branch-chip">
                    {item}
                </div >)}
            </div >
        </div>

    )
}

export default BranchesList;