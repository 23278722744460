import React from "react";


const PerticularsTable = (props) => {
    const { applicationDetails } = props;
    return (
        <div className="grid agreement-table">
            <table>
                <thead>
                    <tr>
                        <th >
                            Sl.No
                        </th>
                        <th >
                            Perticulars
                        </th>
                        <th >
                            Details
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            1
                        </td>
                        <td className="font-semi">
                            Name of the Borrower
                        </td>
                        <td>
                            {applicationDetails?.ParentFullName}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2
                        </td>
                        <td className="font-semi">
                            Address
                        </td>
                        <td>
                            {applicationDetails?.adress}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            3
                        </td>
                        <td className="font-semi">
                            Mobile Number
                        </td>
                        <td>
                            {applicationDetails?.parentPhone}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            4
                        </td>
                        <td className="font-semi">
                            Email
                        </td>
                        <td>
                            {applicationDetails?.parentEmail}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            6
                        </td>
                        <td className="font-semi">
                            Class
                        </td>
                        <td>
                            {applicationDetails?.class}
                        </td>
                    </tr>
                    {/* <tr>
                        <td>
                            7
                        </td>
                        <td className="font-semi">
                            Name of the
                        </td>
                        <td>
                        {applicationDetails?.parentEmail}

                        </td>
                    </tr> */}
                    <tr>
                        <td>
                            8
                        </td>
                        <td className="font-semi">
                            School/Institute along with address
                        </td>
                        <td>
                            {applicationDetails?.institutename}, {applicationDetails?.location}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            9
                        </td>
                        <td>
                            Nature of the Loan
                        </td>
                        <td>
                            Education Loan
                        </td>
                    </tr>
                    <tr>
                        <td>
                            10
                        </td>
                        <td>
                            Purpose
                        </td>
                        <td>
                            Towards Payment of Education Fees
                        </td>
                    </tr>
                    <tr>
                        <td>
                            11
                        </td>
                        <td>
                            Loan
                        </td>
                        <td>
                            {applicationDetails?.courseFee}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            12
                        </td>
                        <td>
                            Rate of Interest
                        </td>
                        <td>
                            To be borne by educational institution with an IRR of 14%-30%

                        </td>
                    </tr>
                    <tr>
                        <td>
                            13
                        </td>
                        <td>
                            Rate of Default Interest
                        </td>
                        <td>
                            2%
                        </td>
                    </tr>
                    <tr>
                        <td>
                            14
                        </td>
                        <td className="font-semi">
                            Tranches/EMIs
                        </td>
                        <td>
                            {applicationDetails?.tenure}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            15
                        </td>
                        <td>
                            Mode of Payment
                        </td>
                        <td>
                            NACH Mandate / Auto Debit from Customer Account. In case NACH/Auto Debit is unsuccessful for any reason, EMI repayment via UPI/Netbanking/Debit Car via logging into the Preksha Account
                        </td>
                    </tr>
                    <tr>
                        <td>
                            16
                        </td>
                        <td className="font-semi">
                            Tenure
                        </td>
                        <td>
                            {applicationDetails?.tenure}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            17
                        </td>
                        <td>
                            Pre Payment Charges
                        </td>
                        <td>
                            Nil
                        </td>
                    </tr>
                    <tr>
                        <td>
                            18
                        </td>
                        <td>
                            Foreclosure Charges
                        </td>
                        <td>
                            Nil
                        </td>
                    </tr>
                    <tr>
                        <td>
                            19
                        </td>
                        <td>
                            Fixed Default Charges
                        </td>
                        <td>
                            Rs.750/- plus GST
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default PerticularsTable;