import React from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import api from "../../api/services";
import { Toast } from 'primereact/toast';
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

const ConfirmationPopup = (props) => {
    const navigate = useNavigate();
    const { visible, popupType, selectedRecord, title, action } = props;
    const toast = useRef(null);

    const onClickApprove = () => {
        api.put('/applications/updateApplicationStatus/' + selectedRecord._id, { "status": popupType === 'REJECT' ? 'DECLINED' : "APPROVED" }).then(res => {
            if (res.status === 200 && res.data.result) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message });
                props.setVisible(false);

            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.message });
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
        });
    }

    const onClickYes = () => {
        api.delete('/applications/applicationDelete/' + selectedRecord._id).then(res => {
            if (res.status === 200 && res.data) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: "Application deleted successfully" });
                props.setVisible();

            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.message });
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
        });
    }

    const footerContent = () => {
        return (
            <>
                {action !== 'DELETE' ? <div>
                    {selectedRecord.bureauAnalysis && selectedRecord.scoremeBSA ? <div>
                        <Button label="Yes" size="small" className="success" onClick={() => { onClickApprove() }} />
                        <Button label="No" size="small" className="ml-2 danger" onClick={() => { props.setVisible(false); }} />
                    </div> : <Button label="OK" size="small" className="success" onClick={() => { props.setVisible(false); }} />}
                </div> : <Button label="Yes" size="small" className="success" onClick={() => { onClickYes() }} />}
            </>
        )
    };

    return (
        <div className="card flex justify-content-center">
            <Dialog header={popupType + ' ' + 'Application'} visible={visible} onHide={() => { props.setVisible(false) }} footer={footerContent} closeOnEscape>
                <p className="m-0">
                    {title}
                </p>
            </Dialog>
            <Toast ref={toast} />

        </div>
    )
}

export default ConfirmationPopup;