import React from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useState } from "react";
import { Button } from "primereact/button";
import { useEffect } from "react";
import api from "../../api/services";
import { Toast } from 'primereact/toast';
import { useRef } from "react";
import ConfirmationPopup from "../../components/confirmationPopup/ConfirmationPopup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FilterMatchMode } from 'primereact/api';


const columns = [
    { field: 'applicationNumber', header: 'Application Number', width: '200px', filter: true },

    { field: 'instituteName', header: 'School Name', width: '200px', filter: true },
    { field: 'location', header: 'Location', width: '200px', filter: true },
    { field: 'studentName', header: 'Student Name', width: '200px', body: true, filter: true },
    { field: 'courseName', header: 'Course', width: '200px', filter: true },
    { field: 'class', header: 'Class', width: '200px', filter: true },
    { field: 'instituteStudentId', header: 'UID', width: '200px', filter: true },
    { field: 'ParentFullName', header: 'Name of Applicant', width: '200px', filter: true },
    { field: 'parentPhone', header: 'Registration Number', width: '200px', filter: true },
    { field: 'employmentType', header: 'Employement Type', width: '200px', filter: true },
    { field: 'dob', header: 'Date of Birth', width: '200px', body: true },
    { field: 'bsaOutput', header: 'BSA Output Analysis', width: '200px', body: true, },
    { field: 'creditScore', header: 'Credit Score', width: '200px', body: true },
    { field: 'nbfcPartner', header: 'NBFC Partner', width: '200px' },
    { field: 'courseFee', header: 'Funding Amount', width: '200px', filter: true },
    { field: 'tenure', header: 'EMI Plan', width: '150px', filter: true },
    { header: 'Actions', field: 'actions', width: '200px', body: true, width: '300px' },
];


const ApplicationManagement = () => {
    const toast = useRef(null);
    const dt = useRef(null);
    const navigate = useNavigate();
    const [visiblePopup, setVisiblePopup] = useState(false);
    const [popupType, setPopupType] = useState('');
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [applications, setApplications] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState({})
    const [title, setTitle] = useState('');
    const [selectedAction, setAction] = useState('');

    const [filters, setFilters] = useState({
        instituteName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        acdemicYear: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        studentName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        courseFee: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        interestRate: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        courseName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        tenure: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        employmentType: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        ParentFullName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        class: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        instituteStudentId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        location: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        parentPhone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });

    useEffect(() => {
        getApplicatonList();
    }, []);
    const getApplicatonList = () => {
        api.get('/applications/getApplications').then(res => {
            if (res.status === 200 && res?.data?.result) {
                setApplications(res.data.result)
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'ERROR', detail: err.message });
        })
    };

    const getMeCibilReport = (element) => {
        api.post('/scoreme/getGeneratedReport/' + element?._id, {
            "referenceId": element?.report?.referenceId
        }).then(res => {
            if (res.status === 200 && res.data.result !== null) {
                getApplicatonList();
                toast.current.show({ severity: 'success', summary: 'SUCCESS', detail: res.data.message });
            } else {
                toast.current.show({ severity: 'error', summary: 'ERROR', detail: res.data.message });
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'ERROR', detail: err.message });
        })
    }
    const getMeBsaReport = (element) => {
        api.post('/scoreme/getBSAReportUrls/' + element?._id, {
            "referenceId": element?.scoremeBSA?.refId
        }).then(res => {
            if (res.status === 200 && res.data.result !== null) {
                if (res.data.message === "Incorrect File.") {
                    toast.current.show({ severity: 'error', summary: 'ERROR', detail: res.data.message });
                    return
                }
                toast.current.show({ severity: 'success', summary: 'SUCCESS', detail: res.data.message });
                getApplicatonList();
            } else {
                toast.current.show({ severity: 'error', summary: 'ERROR', detail: res.data.message });
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'ERROR', detail: err.message });
        })
    }

    const downloadBSAReport = (e) => {
        const config = {
            method: 'get',
            url: e.bsaReportURLS.excelUrl,
            responseType: 'blob',
            headers: {
                'clientId': '3ae655d05f8696c0787763da4f90255d',
                'clientSecret': '00028c861f5ea259fb47891ce606f5d35d4fb70c9ecee9174d1b786ced2cf252'
            }
        };

        axios(config)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'BSA_Report.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate('/otp-login');
                }
            });
    }

    const onClickAction = (element, type) => {
        setAction(type);
        setSelectedRecord(element);
        setVisiblePopup(true);
        setPopupType(type);
        if (type === 'DELETE') {
            setTitle('Are you sure you want to delete this application?');
            return
        }
        if (!element?.bureauAnalysis || !element.scoremeURLs || !element.bsaReportURLS) {
            setTitle('Check Credit Score and BSA Report first');
        } else {
            setTitle("Are you sure you want to" + " " + type + " " + "this application ?")
        }

    }

    const getCibilReport = (url, id) => {
        api.post('/scoreme/checkCreditReport/' + id, {
            "reportURL": url
        }).then(res => {
            if (res.status === 200 && res.data.result !== '') {
                toast.current.show({ severity: 'success', summary: 'SUCCESS', detail: res.data.message });
                getApplicatonList();
            } else {
                toast.current.show({ severity: 'error', summary: 'ERROR', detail: res.data.message });
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.clear();
                navigate('/otp-login');
                return
            }
            toast.current.show({ severity: 'error', summary: 'ERROR', detail: err.message });
        })
    }

    const getDetails = (type, element) => {
        if (type === 'CREDIT_SCORE') {
            getCibilReport(element.scoremeURLs.jsonUrl, element._id);
        } else {
            // --- 
        }

    }

    const generateReport = (type, element) => {
        if (type === 'BSA') getMeBsaReport(element);
        else getMeCibilReport(element);
    }

    const setBodyTemplate = (e, col) => {
        switch (col.field) {
            case 'bsaOutput':
                return (
                    <>
                        {e.applicationStatus?.KYC_FORM === 'SUCCESS' ?
                            <div>
                                {!e?.bsaReportURLS ? <Button label="Generate Report" size="small" onClick={() => generateReport('BSA', e)} /> :
                                    <Button label="Download Report" size="small" onClick={() => downloadBSAReport(e)} />}
                            </div> : 'KYC Pending'
                        }

                    </>
                )
                break;
            case 'studentName':
                return (
                    <div>
                        {e?.firstName} {e?.lastName}
                    </div>
                )
                break;

            case 'creditScore':
                return (
                    <>
                        {e.applicationStatus?.KYC_FORM === 'SUCCESS' ?
                            <div>
                                {e?.bureauAnalysis?.score ? <div>
                                    {e?.bureauAnalysis?.score}
                                </div> : !e?.scoremeURLs ? <Button label="Generate Report" size="small" onClick={() => generateReport('CREDIT_SCORE', e)} /> : <Button label="Get Details" size="small" onClick={() => getDetails('CREDIT_SCORE', e)} />}

                            </div> : 'KYC Pending'
                        }
                    </>
                )
                break;

            case 'dob':
                return (
                    <>
                        {e.dob ? <div>
                            {("0" + new Date(e.dob).getDate()).slice(-2) + '-' + ("0" + (new Date(e.dob).getMonth() + 1)).slice(-2) + '-' + new Date(e.dob).getFullYear()}
                        </div> : ''}
                    </>
                )
                break;
            default:
                return (
                    <>
                        <div>
                            {e.applicationStatus?.KYC_FORM === 'SUCCESS' ? <div>
                                {e.loanStatus.status === 'DRAFT' || e.loanStatus.status === 'PENDING' ? <>
                                    <Button label="Approve" size="small" className="success" onClick={() => { onClickAction(e, 'APPROVE') }} />
                                    <Button label="Reject" size="small" className="ml-2 danger" onClick={() => { onClickAction(e, 'REJECT') }} />
                                </> : <p className={`m-0 ${e.loanStatus.status}`}>
                                    {e.loanStatus.status}
                                </p>}
                            </div> : "KYC Pending"
                            }
                        </div>
                        <Button label="Delete" size="small" className="success ml-2" onClick={() => { onClickAction(e, 'DELETE') }} />
                    </>
                )
                break;
        }

    };
    const exportColumns = columns.map((col) => ({ title: col.header, dataKey: col.field }));
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, applications);
                doc.save('products.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            console.log('applications', applications);
            const worksheet = xlsx.utils.json_to_sheet(applications);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'applications');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            {/* <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" /> */}
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
            {/* <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" /> */}
        </div>
    );

    return (
        <div className="grid">
            <div className="col-12">
                <p className="page-title mb-0">Application Management</p>
            </div>
            <div className="col-12">
                <DataTable header={header} value={applications} filters={filters} scrollable paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} scrollHeight="700px" filterDisplay="row" >
                    {columns.map((col, i) => (
                        <Column filter={col.filter} key={col.field} field={col.field} header={col.header} style={{ minWidth: col.width }} body={col.body ? (e) => setBodyTemplate(e, col) : null}></Column>
                    ))}
                </DataTable>
            </div>
            <Toast ref={toast} />
            <ConfirmationPopup visible={visiblePopup} popupType={popupType} selectedRecord={selectedRecord} title={title} setVisible={() => { setVisiblePopup(false); getApplicatonList(); }} action={selectedAction} />
        </div>
    )
};

export default ApplicationManagement;