import React, { useState } from "react";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { useLocation, useNavigate } from "react-router-dom";
import { MENU_ITEMS } from "../../constants/MenuItems";
import { Menu } from 'primereact/menu';
import { useRef } from "react";
import LOGOUT from '../../assets/images/logout.png';
import LOGO from '../../assets/preksha-logo.svg';
import NO_USER from '../../assets/user.png';
import './Header.scss';

const Header = () => {
    const location = useLocation();
    const menu = useRef(null);
    const navigate = useNavigate();
    const [visibleRight, setVisibleRight] = useState(false);
    const currentUser = localStorage.getItem('currentUser');
    const items = [
        {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: () => {
                localStorage.clear();
                navigate('/otp-login');
            }
        },
    ];

    const rightContents = () => {

        return (
            <div >
                {(location.pathname === '/' || location.pathname === '/new-user-home') && currentUser && <div className="menu-items relative">
                    <Button label="Contact Us" className="p-button-outlined mr-4" />
                    <img src={NO_USER} alt="no user" className="profile-pic pointer" onClick={(e) => menu.current.toggle(e)}
                    />
                    <Menu model={items} popup ref={menu} />
                </div>}
                {(location.pathname !== '/' && location.pathname !== '/new-user-home' && location.pathname !== '/otp-existing-user') && currentUser && <Button label="Go to Home" aria-label="Submit" onClick={() => { navigate('/') }} />}

                {location.pathname !== '/otp-login' && <div className="menu-bar">
                    <i className="pi pi-bars ml-3" onClick={() => setVisibleRight(true)}></i>
                    <Sidebar visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                        {MENU_ITEMS.map((item, index) => <div key={index} className={`flex align-items-center cursor-pointer menu-item ${location.pathname === item.path ? " menu-item-active" : ""}`}
                            onClick={() => { navigate(item.path); setVisibleRight(false); }}>
                            <img src={item.icon} alt={item.label} className="mr-2" />
                            {item.label}
                        </div >)}
                        <div className='flex align-items-center cursor-pointer menu-item ' onClick={() => { localStorage.clear(); setVisibleRight(false); navigate('/otp-login'); }}>
                            <img src={LOGOUT} alt="logout" className="mr-2" />
                            Logout
                        </div >
                    </Sidebar>
                </div>}
            </div>
        )
    }
    const leftContents = () => {
        return (
            <React.Fragment>
                <img src={LOGO} alt="logo" />
            </React.Fragment>
        )
    }
    return (
        <Toolbar left={leftContents} right={rightContents} className="ml-2 mr-2" />
    )
}

export default Header;